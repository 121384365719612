import React from "react";
import { Helmet } from "react-helmet";

function AboutUs() {
  return (
    <div>
      <Helmet>
        <script src="assets/js/main.js" type="text/javascript" />
      </Helmet>
      <div>
        {/* Start Page Title */}
        <div className="page-title-area">
          <div className="container">
            <div className="page-title-content">
              <h2>About Us</h2>
              <ul>
                <li>
                  <a href="Home">Home</a>
                </li>
                <li>About Us</li>
              </ul>
            </div>
          </div>
        </div>
        {/* End Page Title */}
        {/* Start About Area */}
        <section className="about-area ptb-100">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-12">
                <div className="about-image">
                  <img
                    src="assets/img/about/img1.jpg"
                    className="shadow"
                    alt="about-us"
                  />
                  <img
                    src="assets/img/about/img2.jpg"
                    className="shadow"
                    alt="about-us"
                  />
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="about-content">
                  <span className="sub-title">About Us</span>
                  <h2>Xton Trusted Online Shopping Site in the World</h2>
                  <h6>
                    Xton.com offers you flexible and responsive shopping
                    experience.
                  </h6>
                  <p>
                    <strong>Xton</strong> is an eCommerce website which features
                    millions of products, i.e. clothes, shoes, bags, electronic
                    items and much more, with all at incredible prices.
                  </p>
                  <p>
                    One of the most popular on the web is shopping. Lorem ipsum
                    dolor sit amet, consectetur adipiscing elit.
                  </p>
                  <div className="features-text">
                    <h5>
                      <i className="bx bx-planet" />
                      Ships to more than 10 countries and regions
                    </h5>
                    <p>
                      We provide customers with a hassle-free and worry-free
                      international shopping experience from buying to delivery.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="about-inner-area">
              <div className="row">
                <div className="col-lg-4 col-md-6 col-sm-6">
                  <div className="about-text">
                    <h3>Our Story</h3>
                    <p>One of the most popular on the web is shopping.</p>
                    <ul className="features-list">
                      <li>
                        <i className="bx bx-check" /> People like Xton
                      </li>
                      <li>
                        <i className="bx bx-check" /> World's finest Xton
                      </li>
                      <li>
                        <i className="bx bx-check" /> The original Xton
                      </li>
                      <li>
                        <i className="bx bx-check" /> We trust Xton
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6">
                  <div className="about-text">
                    <h3>Our Values</h3>
                    <p>The best of both worlds. Store and web.</p>
                    <ul className="features-list">
                      <li>
                        <i className="bx bx-check" /> Always in style!
                      </li>
                      <li>
                        <i className="bx bx-check" /> Discover your favorite
                        shopping
                      </li>
                      <li>
                        <i className="bx bx-check" /> Find yourself
                      </li>
                      <li>
                        <i className="bx bx-check" /> Feel-good shopping
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                  <div className="about-text">
                    <h3>Our Promise</h3>
                    <p>Rediscover a great shopping tradition</p>
                    <ul className="features-list">
                      <li>
                        <i className="bx bx-check" /> Get better shopping
                      </li>
                      <li>
                        <i className="bx bx-check" /> Love shopping again
                      </li>
                      <li>
                        <i className="bx bx-check" /> Online shopping.
                      </li>
                      <li>
                        <i className="bx bx-check" /> Shopping for all seasons
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* End About Area */}
        {/* Start Offer Area */}
        <section
          className="offer-area bg-image1 ptb-100 jarallax"
          data-jarallax='{"speed": 0.3}'
        >
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-5 col-md-6">
                <div className="offer-content">
                  <span className="sub-title">Limited Time Offer!</span>
                  <h2>-40% OFF</h2>
                  <p>Get The Best Deals Now</p>
                  <a href="Home" className="default-btn">
                    Discover Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* End Offer Area */}
        {/* Start Partner Area */}
        <div className="partner-area ptb-70">
          <div className="container">
            <div className="section-title">
              <h2>Our Partners</h2>
            </div>
            <div className="partner-slides owl-carousel owl-theme">
              <div className="partner-item">
                <a href="Home">
                  <img src="assets/img/partner/partner1.png" alt="about-us" />
                </a>
              </div>
              <div className="partner-item">
                <a href="Home">
                  <img src="assets/img/partner/partner2.png" alt="about-us" />
                </a>
              </div>
              <div className="partner-item">
                <a href="Home">
                  <img src="assets/img/partner/partner3.png" alt="about-us" />
                </a>
              </div>
              <div className="partner-item">
                <a href="Home">
                  <img src="assets/img/partner/partner4.png" alt="about-us" />
                </a>
              </div>
              <div className="partner-item">
                <a href="Home">
                  <img src="assets/img/partner/partner5.png" alt="about-us" />
                </a>
              </div>
              <div className="partner-item">
                <a href="Home">
                  <img src="assets/img/partner/partner6.png" alt="about-us" />
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* End Partner Area */}
        {/* Start Testimonials Area */}
        <section className="testimonials-area ptb-100">
          <div className="container">
            <div className="section-title">
              <span className="sub-title">Testimonials</span>
              <h2>What Clients Says About Us</h2>
            </div>
            <div className="testimonials-slides owl-carousel owl-theme">
              <div className="single-testimonials-item">
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book.
                </p>
                <div className="info">
                  <img
                    src="assets/img/user1.jpg"
                    className="shadow rounded-circle"
                    alt="about-us"
                  />
                  <h3>John Smith</h3>
                  <span>Student</span>
                </div>
              </div>
              <div className="single-testimonials-item">
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book.
                </p>
                <div className="info">
                  <img
                    src="assets/img/user2.jpg"
                    className="shadow rounded-circle"
                    alt="about-us"
                  />
                  <h3>Sarah Taylor</h3>
                  <span>Student</span>
                </div>
              </div>
              <div className="single-testimonials-item">
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book.
                </p>
                <div className="info">
                  <img
                    src="assets/img/user3.jpg"
                    className="shadow rounded-circle"
                    alt="about-us"
                  />
                  <h3>David Warner</h3>
                  <span>Student</span>
                </div>
              </div>
              <div className="single-testimonials-item">
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book.
                </p>
                <div className="info">
                  <img
                    src="assets/img/user4.jpg"
                    className="shadow rounded-circle"
                    alt="about-us"
                  />
                  <h3>James Anderson</h3>
                  <span>Student</span>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* End Testimonials Area */}
        {/* Start Facility Area */}
        <section className="facility-area pb-70">
          <div className="container">
            <div className="facility-slides owl-carousel owl-theme">
              <div className="single-facility-box">
                <div className="icon">
                  <i className="flaticon-tracking" />
                </div>
                <h3>Free Shipping Worldwide</h3>
              </div>
              <div className="single-facility-box">
                <div className="icon">
                  <i className="flaticon-return" />
                </div>
                <h3>Easy Return Policy</h3>
              </div>
              <div className="single-facility-box">
                <div className="icon">
                  <i className="flaticon-shuffle" />
                </div>
                <h3>7 Day Exchange Policy</h3>
              </div>
              <div className="single-facility-box">
                <div className="icon">
                  <i className="flaticon-sale" />
                </div>
                <h3>Weekend Discount Coupon</h3>
              </div>
              <div className="single-facility-box">
                <div className="icon">
                  <i className="flaticon-credit-card" />
                </div>
                <h3>Secure Payment Methods</h3>
              </div>
              <div className="single-facility-box">
                <div className="icon">
                  <i className="flaticon-location" />
                </div>
                <h3>Track Your Package</h3>
              </div>
              <div className="single-facility-box">
                <div className="icon">
                  <i className="flaticon-customer-service" />
                </div>
                <h3>24/7 Customer Support</h3>
              </div>
            </div>
          </div>
        </section>
        {/* End Facility Area */}
      </div>
    </div>
  );
}

export default AboutUs;
