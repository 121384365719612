import React from "react";
import { Link } from "react-router-dom";
import ApparelsProducts from "../products/ApparelsProducts";
import AccessoriesProducts from "../products/AccessoriesProducts";

function TopSellingProducts() {

  const topSellingProducts = ['S11342','SO3106','MO8931','MO8132','MO6110','KC2941']

  const allProducts = ApparelsProducts.concat(AccessoriesProducts);

  let productsData = allProducts.filter(product =>
    topSellingProducts.includes(product.master_code)
  );

  productsData = productsData.filter(product =>
    product.variants[0].sku !== 'S11342-NY-S'
  );

  return (
    <div>
      <section className="products-area pb-70">
        <div className="container">
          <div className="section-title">
            <span className="sub-title">See Our Collection</span>
            <h2>Top Selling Products</h2>
          </div>
          <div className="row">
            {productsData.map((product, index) => (
              <div key={index} className="col-lg-3 col-md-6 col-sm-6">
                <div className="single-products-box">
                  <div className="products-image">
                    <Link to="/ProductDetails" state={{ product }}>
                      <img
                        src={product.variants[0].digital_assets[0].url}
                        className="main-image"
                        alt={product.product_name}
                      />
                      <img
                        src={
                          product.variants[0].digital_assets[1]?.url ||
                          product.variants[0].digital_assets[0].url
                        }
                        className="hover-image"
                        alt={product.product_name}
                      />
                    </Link>
                  </div>
                  <div className="products-content">
                    <h3>
                      <Link to="/ProductDetails" state={{ product }}>
                        {product.master_code} - {product.product_name} -{" "}
                        {product.product_class}
                      </Link>
                    </h3>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
}

export default TopSellingProducts;
