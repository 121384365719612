import React from 'react';

function VideoSection() {
  return (
    <div style={{ width: '100%', overflow: 'hidden' }}>
      <video
        style={{
          width: '100%',
          height: 'auto',
          objectFit: 'cover', // Ensures the video covers the full container without distorting
        }}
        src="/assets/video/video.mp4" // Replace with your video path or URL
        muted
        loop
        autoPlay
        playsInline
      >
        Your browser does not support the video tag.
      </video>
    </div>
  );
}

export default VideoSection;
