import React from "react";
import { Link } from "react-router-dom";
function Footer() {
  return (
    <div>
      {/* Start Footer Area */}
      <footer className="footer-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="single-footer-widget">
                <h3>About The Store</h3>
                <div className="about-the-store">
                  <p>One of the most popular on the web is shopping.</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="single-footer-widget pl-4">
                <h3>Quick Links</h3>
                <ul className="quick-links">
                  <li>
                    <Link to="/Home">Home</Link>
                  </li>
                  <li>
                    <Link to="/Products">Products</Link>
                  </li>
                  <li>
                    <Link to="/ContactUs">Contact Us</Link>
                  </li>
                  <li>
                    <Link to="/ContactUs">Customer Services</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="single-footer-widget">
                <h3>Customer Support</h3>
                <ul className="customer-support">
                  <li>
                    <Link to="Login">My Account</Link>
                  </li>
                  <li>
                    <Link to="CheckOut">Checkout</Link>
                  </li>
                  <li>
                    <Link to="Cart">Cart</Link>
                  </li>
                  <li>
                    <Link to="Login">FAQ's</Link>
                  </li>
                  <li>
                    <Link to="ContactUs">Order Tracking</Link>
                  </li>
                  <li>
                    <Link to="ContactUs">Help &amp; Support</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="single-footer-widget">
                <h3>Newsletter</h3>
                <div className="footer-newsletter-box">
                  <p>To get the latest news and latest updates from us.</p>
                  <form className="newsletter-form" data-bs-toggle="validator">
                    <label>Your E-mail Address:</label>
                    <input
                      type="email"
                      className="input-newsletter"
                      placeholder="Enter your email"
                      name="EMAIL"
                      required
                      autoComplete="off"
                    />
                    <button type="submit">Subscribe</button>
                    <div id="validator-newsletter" className="form-result" />
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-bottom-area">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-6">
                <p>
                  © Xton is Proudly Owned by{" "}
                  <a href="https://hibootstrap.com/">HiBootstrap</a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="lines">
          <div className="line" />
          <div className="line" />
          <div className="line" />
        </div>
      </footer>
      {/* End Footer Area */}
    </div>
  );
}

export default Footer;
