import {BrowserRouter as Router,Route,Routes} from 'react-router-dom'
import Header from "./components/Header";
import Footer from './components/Footer';
import Products from './components/Products';
import Contact from './components/Contact';
import Login from './components/Login';
import Cart from './components/Cart';
import CheckOut from './components/CheckOut';
import Home from './components/Home';
import AboutUs from './components/AboutUs';
import ProductDetails from './components/ProductDetails';

function App() {
  return (
    <div className="App">
        <Router>
        <Header></Header>
        <Routes>
          <Route exact path='/' element={<Home/>}/>
          <Route exact path='/Home' element={<Home/>}/>
          <Route exact path='/AboutUs' element={<AboutUs/>}/>
          <Route exact path='/Products' element={<Products/>}/>
          <Route exact path='/Products/:category' element={<Products/>}/>
          <Route exact path='/ProductDetails' element={<ProductDetails/>}/>
          <Route exact path='/ContactUs' element={<Contact/>}/>
          <Route exact path='/Login' element={<Login/>}/>
          <Route exact path='/Cart' element={<Cart/>}/>
          <Route exact path='/CheckOut' element={<CheckOut/>}/>
        </Routes>
        <Footer></Footer>
        </Router>
    </div>
  );
}

export default App;
