import React from "react";
import ApparelsProducts from "../products/ApparelsProducts";
import AccessoriesProducts from "../products/AccessoriesProducts";
import { Link, useParams } from "react-router-dom";

function Products() {
  const { category } = useParams();
  let productsData = []; // Initialize as an empty array

  const filterProducts = (products, mainCategory, category) => {
    if (mainCategory) {
      return products.filter(product => product.main_category_name === mainCategory);
    } else {
      return products.filter(product => product.category_name === category);
    }
  };

  switch (category) {
    case "Apparels":
      productsData = ApparelsProducts;
      break;
    case "Men":
    case "Women":
    case "Kids":
      productsData = filterProducts(ApparelsProducts, category, null);
      break;
    case "Accessories":
      productsData = AccessoriesProducts;
      break;
    case "Games":
    case "Bags":
    case "Drinkware":
    case "Desk-Accessories":
    case "Stationaries":
    case "Gadgets-Tech":
    case "Eco-Friendly":
    case "Home-Living":
    case "Fitness-Wellness":
      productsData = filterProducts(AccessoriesProducts, category, null);
      break;
    default:
      // Load all products if no category is specified or if category doesn't match
      productsData = [...ApparelsProducts, ...AccessoriesProducts];
      break;
  }

  return (
    <div>
      <div>
        {/* Start Page Title */}
        <div className="page-title-area">
          <div className="container">
            <div className="page-title-content">
              <ul>
                <li>
                  <Link to="/Home">Home</Link>
                </li>
                <li>Products</li>
              </ul>
            </div>
          </div>
        </div>
        {/* End Page Title */}
        
        {/* Start Products Area */}
        <section className="products-area pt-100 pb-70">
          <div className="container">
            <div className="products-filter-options">
              <div className="row align-items-center">
                <div className="col-lg-4 col-md-4">
                  <div className="d-lg-flex d-md-flex align-items-center">
                    <span className="sub-title d-none d-lg-block d-md-block">
                      View:
                    </span>
                    <div className="view-list-row d-none d-lg-block d-md-block">
                      <div className="view-column">
                        <a href="Home" className="icon-view-two">
                          <span></span>
                          <span></span>
                        </a>

                        <a href="Home" className="icon-view-three">
                          <span></span>
                          <span></span>
                          <span></span>
                        </a>

                        <a href="Home" className="icon-view-four active">
                          <span></span>
                          <span></span>
                          <span></span>
                          <span></span>
                        </a>

                        <a href="Home" className="view-grid-switch">
                          <span></span>
                          <span></span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4">
                  <p>Showing all {productsData.length} products</p>
                </div>
              </div>
            </div>
            <div
              id="products-collections-filter"
              className="row products-col-four"
            >
              {productsData.map((product, index) => (
                <div
                  key={index}
                  className="col-lg-4 col-md-6 col-sm-6 products-col-item"
                >
                  <div className="single-productsBox">
                    <div className="products-image">
                      <Link to="/ProductDetails" state={{ product }}>
                        <img
                          src={product.variants[0].digital_assets[0].url}
                          className="main-image"
                          alt={product.product_name}
                        />
                        <img
                          src={
                            product.variants[0].digital_assets[1]?.url ||
                            product.variants[0].digital_assets[0].url
                          }
                          className="hover-image"
                          alt={product.product_name}
                        />
                      </Link>
                      {product.isNew && <div className="new-tag">New!</div>}
                    </div>
                    <div className="products-content">
                      <span className="category">{product.category_name}</span>
                      <h3>
                        <Link to="/ProductDetails" state={{ product }}>
                          {product.product_name}
                        </Link>
                      </h3>
                    </div>
                    {product.discount && (
                      <span className="products-discount">
                        <span>{product.discount}% OFF</span>
                      </span>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
        {/* End Products Area */}
      </div>
    </div>
  );
}

export default Products;
