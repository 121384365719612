const ApparelsProducts = [
  {
    main_category_name: "Men",
    category_name: "Men-Polo-Tshirts",
    master_code: "S11342",
    master_id: "40008019",
    type_of_products: "textile",
    commodity_code: "6105 1000",
    number_of_print_positions: "5",
    country_of_origin: "BD",
    brand: "Sol's",
    product_name: "SUMMER II",
    category_code: "MOBTEX_PSHMEN",
    product_class: "Polo shirt",
    dimensions: "63 X 41 X 26 CM",
    length: "0",
    length_unit: "cm",
    width: "0",
    width_unit: "cm",
    height: "0",
    height_unit: "cm",
    volume: "1.06",
    volume_unit: "cdm3",
    gross_weight: "0.213",
    gross_weight_unit: "kg",
    net_weight: "0.169",
    net_weight_unit: "kg",
    inner_carton_quantity: "5",
    outer_carton_quantity: "50",
    carton_length: "0.54",
    carton_length_unit: "m",
    carton_width: "0.38",
    carton_width_unit: "m",
    carton_height: "0.26",
    carton_height_unit: "m",
    carton_volume: "0.053",
    carton_volume_unit: "m3",
    carton_gross_weight: "10.65",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/size-chart/s11342-sizechart.pdf",
        type: "document",
        subtype: "size_chart",
      },
      {
        url: "https://cdn1.midocean.com/document/oeko-tex-certificate/s11342-oekotex.pdf",
        type: "document",
        subtype: "oeko-tex_certificate",
      },
      {
        url: "https://cdn1.midocean.com/document/gots-certificate/s11342-gots.pdf",
        type: "document",
        subtype: "gots_certificate",
      },
      {
        url: "https://cdn1.midocean.com/document/peta-certificate/s11342-peta.pdf",
        type: "document",
        subtype: "peta_certificate",
      },
    ],
    short_description: "SUMMER II MEN Polo 170g",
    long_description:
      "SOL'S SUMMER II,  men's polo shirt, 100% combed ringspun cotton piqué 170, rib collar, reinforcing tape on neck, reinforced button placket, short sleeves, tone on tone 3 button placket, straight hem with side slits, cut and sewn, spare button on the inside seam, (1) ash : 98 % cotton / 2 % viscose, (2) grey melange : 85 % cotton / 15 % viscose. About sizes matching, please refer to the size chart in the product documentation section.",
    gender: "MEN",
    material: "Cotton",
    printable: "yes",
    variants: [
      {
        variant_id: "10218122",
        sku: "S11342-BK-S",
        release_date: "2023-08-25",
        product_proposition_category: "378",
        category_level1: "Textile SOLO Group",
        category_level2: "Textile Category",
        category_level3: "Polo's",
        color_description: "Black",
        color_group: "Black",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "3660734063350",
        size_textile: "S",
        color_code: "BK",
        pms_color: "426C",
        digital_assets: [
          {
            url: "/assets/img/apparels/men/polo/BK-with-logo.jpg",
            url_highress: "/assets/img/apparels/men/polo/BK-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s11342-bk.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s11342-bk.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s11342-bk-back.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s11342-bk-back.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s11342-bk-side.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s11342-bk-side.jpg",
            type: "image",
            subtype: "item_picture_side",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s11342-bk-print.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s11342-bk-print.jpg",
            type: "image",
            subtype: "item_picture_printed",
          },
        ],
      },
    ],
  },
  {
    main_category_name: "Men",
    category_name: "Men-Polo-Tshirts",
    master_code: "S11342",
    master_id: "40008019",
    type_of_products: "textile",
    commodity_code: "6105 1000",
    number_of_print_positions: "5",
    country_of_origin: "BD",
    brand: "Sol's",
    product_name: "SUMMER II",
    category_code: "MOBTEX_PSHMEN",
    product_class: "Polo shirt",
    dimensions: "63 X 41 X 26 CM",
    length: "0",
    length_unit: "cm",
    width: "0",
    width_unit: "cm",
    height: "0",
    height_unit: "cm",
    volume: "1.06",
    volume_unit: "cdm3",
    gross_weight: "0.213",
    gross_weight_unit: "kg",
    net_weight: "0.169",
    net_weight_unit: "kg",
    inner_carton_quantity: "5",
    outer_carton_quantity: "50",
    carton_length: "0.54",
    carton_length_unit: "m",
    carton_width: "0.38",
    carton_width_unit: "m",
    carton_height: "0.26",
    carton_height_unit: "m",
    carton_volume: "0.053",
    carton_volume_unit: "m3",
    carton_gross_weight: "10.65",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/size-chart/s11342-sizechart.pdf",
        type: "document",
        subtype: "size_chart",
      },
      {
        url: "https://cdn1.midocean.com/document/oeko-tex-certificate/s11342-oekotex.pdf",
        type: "document",
        subtype: "oeko-tex_certificate",
      },
      {
        url: "https://cdn1.midocean.com/document/gots-certificate/s11342-gots.pdf",
        type: "document",
        subtype: "gots_certificate",
      },
      {
        url: "https://cdn1.midocean.com/document/peta-certificate/s11342-peta.pdf",
        type: "document",
        subtype: "peta_certificate",
      },
    ],
    short_description: "SUMMER II MEN Polo 170g",
    long_description:
      "SOL'S SUMMER II,  men's polo shirt, 100% combed ringspun cotton piqué 170, rib collar, reinforcing tape on neck, reinforced button placket, short sleeves, tone on tone 3 button placket, straight hem with side slits, cut and sewn, spare button on the inside seam, (1) ash : 98 % cotton / 2 % viscose, (2) grey melange : 85 % cotton / 15 % viscose. About sizes matching, please refer to the size chart in the product documentation section.",
    gender: "MEN",
    material: "Cotton",
    printable: "yes",
    variants: [
      {
        variant_id: "10218194",
        sku: "S11342-RD-S",
        release_date: "2023-08-25",
        product_proposition_category: "378",
        category_level1: "Textile SOLO Group",
        category_level2: "Textile Category",
        category_level3: "Polo's",
        color_description: "Red",
        color_group: "Red",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "3660734063503",
        size_textile: "S",
        color_code: "RD",
        pms_color: "200C",
        digital_assets: [
          {
            url: "/assets/img/apparels/men/polo/RD-with-logo.jpg",
            url_highress: "/assets/img/apparels/men/polo/RD-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s11342-rd.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s11342-rd.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s11342-rd-back.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s11342-rd-back.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s11342-rd-side.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s11342-rd-side.jpg",
            type: "image",
            subtype: "item_picture_side",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s11342-rd-print.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s11342-rd-print.jpg",
            type: "image",
            subtype: "item_picture_printed",
          },
        ],
      },
    ],
  },
  {
    main_category_name: "Men",
    category_name: "Men-Polo-Tshirts",
    master_code: "S11342",
    master_id: "40008019",
    type_of_products: "textile",
    commodity_code: "6105 1000",
    number_of_print_positions: "5",
    country_of_origin: "BD",
    brand: "Sol's",
    product_name: "SUMMER II",
    category_code: "MOBTEX_PSHMEN",
    product_class: "Polo shirt",
    dimensions: "63 X 41 X 26 CM",
    length: "0",
    length_unit: "cm",
    width: "0",
    width_unit: "cm",
    height: "0",
    height_unit: "cm",
    volume: "1.06",
    volume_unit: "cdm3",
    gross_weight: "0.213",
    gross_weight_unit: "kg",
    net_weight: "0.169",
    net_weight_unit: "kg",
    inner_carton_quantity: "5",
    outer_carton_quantity: "50",
    carton_length: "0.54",
    carton_length_unit: "m",
    carton_width: "0.38",
    carton_width_unit: "m",
    carton_height: "0.26",
    carton_height_unit: "m",
    carton_volume: "0.053",
    carton_volume_unit: "m3",
    carton_gross_weight: "10.65",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/size-chart/s11342-sizechart.pdf",
        type: "document",
        subtype: "size_chart",
      },
      {
        url: "https://cdn1.midocean.com/document/oeko-tex-certificate/s11342-oekotex.pdf",
        type: "document",
        subtype: "oeko-tex_certificate",
      },
      {
        url: "https://cdn1.midocean.com/document/gots-certificate/s11342-gots.pdf",
        type: "document",
        subtype: "gots_certificate",
      },
      {
        url: "https://cdn1.midocean.com/document/peta-certificate/s11342-peta.pdf",
        type: "document",
        subtype: "peta_certificate",
      },
    ],
    short_description: "SUMMER II MEN Polo 170g",
    long_description:
      "SOL'S SUMMER II,  men's polo shirt, 100% combed ringspun cotton piqué 170, rib collar, reinforcing tape on neck, reinforced button placket, short sleeves, tone on tone 3 button placket, straight hem with side slits, cut and sewn, spare button on the inside seam, (1) ash : 98 % cotton / 2 % viscose, (2) grey melange : 85 % cotton / 15 % viscose. About sizes matching, please refer to the size chart in the product documentation section.",
    gender: "MEN",
    material: "Cotton",
    printable: "yes",
    variants: [
      {
        variant_id: "10218164",
        sku: "S11342-KG-S",
        release_date: "2023-08-25",
        product_proposition_category: "378",
        category_level1: "Textile SOLO Group",
        category_level2: "Textile Category",
        category_level3: "Polo's",
        color_description: "Kelly Green",
        color_group: "Green",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "3660734063756",
        size_textile: "S",
        color_code: "KG",
        pms_color: "3415C",
        digital_assets: [
          {
            url: "/assets/img/apparels/men/polo/KG-with-logo.jpg",
            url_highress: "/assets/img/apparels/men/polo/KG-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s11342-kg.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s11342-kg.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s11342-kg-back.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s11342-kg-back.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s11342-kg-side.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s11342-kg-side.jpg",
            type: "image",
            subtype: "item_picture_side",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s11342-kg-print.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s11342-kg-print.jpg",
            type: "image",
            subtype: "item_picture_printed",
          },
        ],
      },
    ],
  },
  {
    main_category_name: "Men",
    category_name: "Men-Polo-Tshirts",
    master_code: "S11342",
    master_id: "40008019",
    type_of_products: "textile",
    commodity_code: "6105 1000",
    number_of_print_positions: "5",
    country_of_origin: "BD",
    brand: "Sol's",
    product_name: "SUMMER II",
    category_code: "MOBTEX_PSHMEN",
    product_class: "Polo shirt",
    dimensions: "63 X 41 X 26 CM",
    length: "0",
    length_unit: "cm",
    width: "0",
    width_unit: "cm",
    height: "0",
    height_unit: "cm",
    volume: "1.06",
    volume_unit: "cdm3",
    gross_weight: "0.213",
    gross_weight_unit: "kg",
    net_weight: "0.169",
    net_weight_unit: "kg",
    inner_carton_quantity: "5",
    outer_carton_quantity: "50",
    carton_length: "0.54",
    carton_length_unit: "m",
    carton_width: "0.38",
    carton_width_unit: "m",
    carton_height: "0.26",
    carton_height_unit: "m",
    carton_volume: "0.053",
    carton_volume_unit: "m3",
    carton_gross_weight: "10.65",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/size-chart/s11342-sizechart.pdf",
        type: "document",
        subtype: "size_chart",
      },
      {
        url: "https://cdn1.midocean.com/document/oeko-tex-certificate/s11342-oekotex.pdf",
        type: "document",
        subtype: "oeko-tex_certificate",
      },
      {
        url: "https://cdn1.midocean.com/document/gots-certificate/s11342-gots.pdf",
        type: "document",
        subtype: "gots_certificate",
      },
      {
        url: "https://cdn1.midocean.com/document/peta-certificate/s11342-peta.pdf",
        type: "document",
        subtype: "peta_certificate",
      },
    ],
    short_description: "SUMMER II MEN Polo 170g",
    long_description:
      "SOL'S SUMMER II,  men's polo shirt, 100% combed ringspun cotton piqué 170, rib collar, reinforcing tape on neck, reinforced button placket, short sleeves, tone on tone 3 button placket, straight hem with side slits, cut and sewn, spare button on the inside seam, (1) ash : 98 % cotton / 2 % viscose, (2) grey melange : 85 % cotton / 15 % viscose. About sizes matching, please refer to the size chart in the product documentation section.",
    gender: "MEN",
    material: "Cotton",
    printable: "yes",
    variants: [
      {
        variant_id: "10218188",
        sku: "S11342-RB-S",
        release_date: "2023-08-25",
        product_proposition_category: "378",
        category_level1: "Textile SOLO Group",
        category_level2: "Textile Category",
        category_level3: "Polo's",
        color_description: "Royal Blue",
        color_group: "Blue",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "3660734063558",
        size_textile: "S",
        color_code: "RB",
        pms_color: "7686C",
        digital_assets: [
          {
            url: "/assets/img/apparels/men/polo/RB-with-logo.jpg",
            url_highress: "/assets/img/apparels/men/polo/RB-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s11342-rb.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s11342-rb.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s11342-rb-back.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s11342-rb-back.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s11342-rb-side.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s11342-rb-side.jpg",
            type: "image",
            subtype: "item_picture_side",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s11342-rb-print.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s11342-rb-print.jpg",
            type: "image",
            subtype: "item_picture_printed",
          },
        ],
      },
    ],
  },
  {
    main_category_name: "Men",
    category_name: "Men-Polo-Tshirts",
    master_code: "S11342",
    master_id: "40008019",
    type_of_products: "textile",
    commodity_code: "6105 1000",
    number_of_print_positions: "5",
    country_of_origin: "BD",
    brand: "Sol's",
    product_name: "SUMMER II",
    category_code: "MOBTEX_PSHMEN",
    product_class: "Polo shirt",
    dimensions: "63 X 41 X 26 CM",
    length: "0",
    length_unit: "cm",
    width: "0",
    width_unit: "cm",
    height: "0",
    height_unit: "cm",
    volume: "1.06",
    volume_unit: "cdm3",
    gross_weight: "0.213",
    gross_weight_unit: "kg",
    net_weight: "0.169",
    net_weight_unit: "kg",
    inner_carton_quantity: "5",
    outer_carton_quantity: "50",
    carton_length: "0.54",
    carton_length_unit: "m",
    carton_width: "0.38",
    carton_width_unit: "m",
    carton_height: "0.26",
    carton_height_unit: "m",
    carton_volume: "0.053",
    carton_volume_unit: "m3",
    carton_gross_weight: "10.65",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/size-chart/s11342-sizechart.pdf",
        type: "document",
        subtype: "size_chart",
      },
      {
        url: "https://cdn1.midocean.com/document/oeko-tex-certificate/s11342-oekotex.pdf",
        type: "document",
        subtype: "oeko-tex_certificate",
      },
      {
        url: "https://cdn1.midocean.com/document/gots-certificate/s11342-gots.pdf",
        type: "document",
        subtype: "gots_certificate",
      },
      {
        url: "https://cdn1.midocean.com/document/peta-certificate/s11342-peta.pdf",
        type: "document",
        subtype: "peta_certificate",
      },
    ],
    short_description: "SUMMER II MEN Polo 170g",
    long_description:
      "SOL'S SUMMER II,  men's polo shirt, 100% combed ringspun cotton piqué 170, rib collar, reinforcing tape on neck, reinforced button placket, short sleeves, tone on tone 3 button placket, straight hem with side slits, cut and sewn, spare button on the inside seam, (1) ash : 98 % cotton / 2 % viscose, (2) grey melange : 85 % cotton / 15 % viscose. About sizes matching, please refer to the size chart in the product documentation section.",
    gender: "MEN",
    material: "Cotton",
    printable: "yes",
    variants: [
      {
        variant_id: "10218170",
        sku: "S11342-NY-S",
        release_date: "2023-08-25",
        product_proposition_category: "378",
        category_level1: "Textile SOLO Group",
        category_level2: "Textile Category",
        category_level3: "Polo's",
        color_description: "Navy",
        color_group: "Blue",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "3660734063305",
        size_textile: "S",
        color_code: "NY",
        pms_color: "296C",
        digital_assets: [
          {
            url: "/assets/img/apparels/men/polo/NY-with-logo.jpg",
            url_highress: "/assets/img/apparels/men/polo/NY-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s11342-ny.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s11342-ny.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s11342-ny-back.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s11342-ny-back.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s11342-ny-side.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s11342-ny-side.jpg",
            type: "image",
            subtype: "item_picture_side",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s11342-ny-print.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s11342-ny-print.jpg",
            type: "image",
            subtype: "item_picture_printed",
          },
        ],
      },
    ],
  },
  {
    main_category_name: "Men",
    category_name: "Men-Shirts",
    master_code: "S17000",
    master_id: "40008727",
    type_of_products: "textile",
    commodity_code: "6205 2000",
    number_of_print_positions: "3",
    country_of_origin: "BD",
    brand: "Sol's",
    product_name: "BRIGHTON",
    category_code: "MOBTEX_SWSMEN",
    product_class: "Shirt",
    dimensions: "57 X 37 X 30 CM",
    length: "0",
    length_unit: "cm",
    width: "0",
    width_unit: "cm",
    height: "0",
    height_unit: "cm",
    volume: "0.001",
    volume_unit: "cdm3",
    gross_weight: "0.361",
    gross_weight_unit: "kg",
    net_weight: "0.3",
    net_weight_unit: "kg",
    inner_carton_quantity: "1",
    outer_carton_quantity: "20",
    carton_length: "0.57",
    carton_length_unit: "m",
    carton_width: "0.37",
    carton_width_unit: "m",
    carton_height: "0.29",
    carton_height_unit: "m",
    carton_volume: "0.061",
    carton_volume_unit: "m3",
    carton_gross_weight: "7.22",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/size-chart/s17000-sizechart.pdf",
        type: "document",
        subtype: "size_chart",
      },
      {
        url: "https://cdn1.midocean.com/document/oeko-tex-certificate/s17000-oekotex.pdf",
        type: "document",
        subtype: "oeko-tex_certificate",
      },
      {
        url: "https://cdn1.midocean.com/document/peta-certificate/s17000-peta.pdf",
        type: "document",
        subtype: "peta_certificate",
      },
    ],
    short_description: "BRIGHTON STRETCH MEN SHIRT",
    long_description:
      "SOL'S BRIGHTON, long sleeve stretch men's shirt, 97% cotton - 3% elastane poplin stretch 140, fitted cut, long sleeves, single button collar, cuffs with 2 buttons and bound slit, 7 tone-on-tone button placket. About sizes matching, please refer to the size chart in the product documentation section.",
    gender: "MEN",
    printable: "yes",
    variants: [
      {
        variant_id: "10220469",
        sku: "S17000-WH-S",
        release_date: "2023-08-25",
        product_proposition_category: "751",
        category_level1: "Textile SOLO Group",
        category_level2: "Brand",
        category_level3: "SOL'S",
        color_description: "White",
        color_group: "White",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "3660734031564",
        size_textile: "S",
        color_code: "WH",
        pms_color: "WHITE",
        digital_assets: [
          {
            url: "/assets/img/apparels/men/shirts/WH-with-logo.jpg",
            url_highress: "/assets/img/apparels/men/shirts/WH-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s17000-wh.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s17000-wh.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s17000-wh-back.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s17000-wh-back.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s17000-wh-side.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s17000-wh-side.jpg",
            type: "image",
            subtype: "item_picture_side",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s17000-wh-print.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s17000-wh-print.jpg",
            type: "image",
            subtype: "item_picture_printed",
          },
        ],
      },
    ],
  },
  {
    main_category_name: "Men",
    category_name: "Men-Shirts",
    master_code: "S17000",
    master_id: "40008727",
    type_of_products: "textile",
    commodity_code: "6205 2000",
    number_of_print_positions: "3",
    country_of_origin: "BD",
    brand: "Sol's",
    product_name: "BRIGHTON",
    category_code: "MOBTEX_SWSMEN",
    product_class: "Shirt",
    dimensions: "57 X 37 X 30 CM",
    length: "0",
    length_unit: "cm",
    width: "0",
    width_unit: "cm",
    height: "0",
    height_unit: "cm",
    volume: "0.001",
    volume_unit: "cdm3",
    gross_weight: "0.361",
    gross_weight_unit: "kg",
    net_weight: "0.3",
    net_weight_unit: "kg",
    inner_carton_quantity: "1",
    outer_carton_quantity: "20",
    carton_length: "0.57",
    carton_length_unit: "m",
    carton_width: "0.37",
    carton_width_unit: "m",
    carton_height: "0.29",
    carton_height_unit: "m",
    carton_volume: "0.061",
    carton_volume_unit: "m3",
    carton_gross_weight: "7.22",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/size-chart/s17000-sizechart.pdf",
        type: "document",
        subtype: "size_chart",
      },
      {
        url: "https://cdn1.midocean.com/document/oeko-tex-certificate/s17000-oekotex.pdf",
        type: "document",
        subtype: "oeko-tex_certificate",
      },
      {
        url: "https://cdn1.midocean.com/document/peta-certificate/s17000-peta.pdf",
        type: "document",
        subtype: "peta_certificate",
      },
    ],
    short_description: "BRIGHTON STRETCH MEN SHIRT",
    long_description:
      "SOL'S BRIGHTON, long sleeve stretch men's shirt, 97% cotton - 3% elastane poplin stretch 140, fitted cut, long sleeves, single button collar, cuffs with 2 buttons and bound slit, 7 tone-on-tone button placket. About sizes matching, please refer to the size chart in the product documentation section.",
    gender: "MEN",
    printable: "yes",
    variants: [
      {
        variant_id: "10220461",
        sku: "S17000-BK-S",
        release_date: "2023-08-25",
        product_proposition_category: "751",
        category_level1: "Textile SOLO Group",
        category_level2: "Brand",
        category_level3: "SOL'S",
        color_description: "Black",
        color_group: "Black",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "3660734031618",
        size_textile: "S",
        color_code: "BK",
        pms_color: "426C",
        digital_assets: [
          {
            url: "/assets/img/apparels/men/shirts/BK-with-logo.jpg",
            url_highress: "/assets/img/apparels/men/shirts/BK-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s17000-bk.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s17000-bk.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s17000-bk-back.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s17000-bk-back.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s17000-bk-side.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s17000-bk-side.jpg",
            type: "image",
            subtype: "item_picture_side",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s17000-bk-print.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s17000-bk-print.jpg",
            type: "image",
            subtype: "item_picture_printed",
          },
        ],
      },
    ],
  },
  {
    main_category_name: "Men",
    category_name: "Men-Hoodded-Sweat-Shirt",
    master_code: "S03105",
    master_id: "40009056",
    type_of_products: "textile",
    commodity_code: "6110 2099",
    number_of_print_positions: "5",
    country_of_origin: "BD",
    brand: "Sol's",
    product_name: "SPIKE MEN",
    category_code: "MOBTEX_SWJMEN",
    product_class: "Sweat shirt",
    dimensions: "64 X 38 X 54 CM",
    length: "0",
    length_unit: "cm",
    width: "0",
    width_unit: "cm",
    height: "0",
    height_unit: "cm",
    volume: "4.68",
    volume_unit: "cdm3",
    gross_weight: "0.619",
    gross_weight_unit: "kg",
    net_weight: "0.558",
    net_weight_unit: "kg",
    inner_carton_quantity: "5",
    outer_carton_quantity: "25",
    carton_length: "0.58",
    carton_length_unit: "m",
    carton_width: "0.38",
    carton_width_unit: "m",
    carton_height: "0.53",
    carton_height_unit: "m",
    carton_volume: "0.117",
    carton_volume_unit: "m3",
    carton_gross_weight: "15.467",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/size-chart/s03105-sizechart.pdf",
        type: "document",
        subtype: "size_chart",
      },
      {
        url: "https://cdn1.midocean.com/document/oeko-tex-certificate/s03105-oekotex.pdf",
        type: "document",
        subtype: "oeko-tex_certificate",
      },
      {
        url: "https://cdn1.midocean.com/document/peta-certificate/s03105-peta.pdf",
        type: "document",
        subtype: "peta_certificate",
      },
    ],
    short_description: "SPIKE HOOD MEN 280gr",
    long_description:
      "SOL'S SPIKE MEN. Men's zip hoodie, 80% ring spun cotton - 20% polyester Brushed lining, Outside: 100% cotton FLEECE 280 LSF (Low Shrinkage Fabric), Set-in sleeves, 2 front pouch pockets, Cut and sewn, Lined hood with tone-on-tone drawcord, 2x2 ribbed cuffs and hem. Half-moon inside collar, Taped neck, YKK zip. OEKO-TEX®. Only sold with print.",
    gender: "MEN",
    printable: "yes",
    variants: [
      {
        variant_id: "10226078",
        sku: "S03105-WH-S",
        release_date: "2023-08-25",
        product_proposition_category: "379",
        category_level1: "Textile SOLO Group",
        category_level2: "Textile Category",
        category_level3: "Sweat shirts",
        color_description: "White",
        color_group: "White",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "3609371001714",
        size_textile: "S",
        color_code: "WH",
        pms_color: "WHITE",
        digital_assets: [
          {
            url: "/assets/img/apparels/men/hoodie/WH-with-logo.jpg",
            url_highress: "/assets/img/apparels/men/polo/WH-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s03105-wh.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s03105-wh.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s03105-wh-back.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s03105-wh-back.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s03105-wh-side.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s03105-wh-side.jpg",
            type: "image",
            subtype: "item_picture_side",
          },
        ],
      },
    ],
  },
  {
    main_category_name: "Men",
    category_name: "Men-Hoodded-Sweat-Shirt",
    master_code: "S03105",
    master_id: "40009056",
    type_of_products: "textile",
    commodity_code: "6110 2099",
    number_of_print_positions: "5",
    country_of_origin: "BD",
    brand: "Sol's",
    product_name: "SPIKE MEN",
    category_code: "MOBTEX_SWJMEN",
    product_class: "Sweat shirt",
    dimensions: "64 X 38 X 54 CM",
    length: "0",
    length_unit: "cm",
    width: "0",
    width_unit: "cm",
    height: "0",
    height_unit: "cm",
    volume: "4.68",
    volume_unit: "cdm3",
    gross_weight: "0.619",
    gross_weight_unit: "kg",
    net_weight: "0.558",
    net_weight_unit: "kg",
    inner_carton_quantity: "5",
    outer_carton_quantity: "25",
    carton_length: "0.58",
    carton_length_unit: "m",
    carton_width: "0.38",
    carton_width_unit: "m",
    carton_height: "0.53",
    carton_height_unit: "m",
    carton_volume: "0.117",
    carton_volume_unit: "m3",
    carton_gross_weight: "15.467",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/size-chart/s03105-sizechart.pdf",
        type: "document",
        subtype: "size_chart",
      },
      {
        url: "https://cdn1.midocean.com/document/oeko-tex-certificate/s03105-oekotex.pdf",
        type: "document",
        subtype: "oeko-tex_certificate",
      },
      {
        url: "https://cdn1.midocean.com/document/peta-certificate/s03105-peta.pdf",
        type: "document",
        subtype: "peta_certificate",
      },
    ],
    short_description: "SPIKE HOOD MEN 280gr",
    long_description:
      "SOL'S SPIKE MEN. Men's zip hoodie, 80% ring spun cotton - 20% polyester Brushed lining, Outside: 100% cotton FLEECE 280 LSF (Low Shrinkage Fabric), Set-in sleeves, 2 front pouch pockets, Cut and sewn, Lined hood with tone-on-tone drawcord, 2x2 ribbed cuffs and hem. Half-moon inside collar, Taped neck, YKK zip. OEKO-TEX®. Only sold with print.",
    gender: "MEN",
    printable: "yes",
    variants: [
      {
        variant_id: "10226054",
        sku: "S03105-BK-S",
        release_date: "2023-08-25",
        product_proposition_category: "379",
        category_level1: "Textile SOLO Group",
        category_level2: "Textile Category",
        category_level3: "Sweat shirts",
        color_description: "Black",
        color_group: "Black",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "3609371001394",
        size_textile: "S",
        color_code: "BK",
        pms_color: "426C",
        digital_assets: [
          {
            url: "/assets/img/apparels/men/hoodie/BK-with-logo.jpg",
            url_highress: "/assets/img/apparels/men/polo/BK-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s03105-bk.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s03105-bk.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s03105-bk-back.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s03105-bk-back.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s03105-bk-side.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s03105-bk-side.jpg",
            type: "image",
            subtype: "item_picture_side",
          },
        ],
      },
    ],
  },
  {
    main_category_name: "Men",
    category_name: "Round-Neck-TShirt-Male",
    master_code: "S00553",
    master_id: "40007991",
    type_of_products: "textile",
    commodity_code: "6109 1000",
    number_of_print_positions: "5",
    country_of_origin: "BD",
    brand: "Sol's",
    product_name: "REGENT FIT",
    category_code: "MOBTEX_TSHMEN",
    product_class: "T-shirt",
    dimensions: "60 X 39 X 32 CM",
    length: "0",
    length_unit: "cm",
    width: "0",
    width_unit: "cm",
    height: "0",
    height_unit: "cm",
    volume: "0.63",
    volume_unit: "cdm3",
    gross_weight: "0.141",
    gross_weight_unit: "kg",
    net_weight: "0.132",
    net_weight_unit: "kg",
    inner_carton_quantity: "10",
    outer_carton_quantity: "100",
    carton_length: "0.52",
    carton_length_unit: "m",
    carton_width: "0.38",
    carton_width_unit: "m",
    carton_height: "0.32",
    carton_height_unit: "m",
    carton_volume: "0.063",
    carton_volume_unit: "m3",
    carton_gross_weight: "14.06",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/size-chart/s00553-sizechart.pdf",
        type: "document",
        subtype: "size_chart",
      },
      {
        url: "https://cdn1.midocean.com/document/oeko-tex-certificate/s00553-oekotex.pdf",
        type: "document",
        subtype: "oeko-tex_certificate",
      },
      {
        url: "https://cdn1.midocean.com/document/peta-certificate/s00553-peta.pdf",
        type: "document",
        subtype: "peta_certificate",
      },
    ],
    short_description: "REGENT F MEN T-SHIRT 150g",
    long_description:
      "SOL'S REGENT FIT Men's Round Neck Close Fitting T-shirt, 100% semi- combed Ringspun cotton Jersey 150, Tape inside collar, Elastane rib round collar, Short sleeves, Cut and sewn, Fitted cut, (1) Heather pink : 60 % cotton / 40 % polyester, (2) Heather sky : 60 % cotton / 40 % polyester, (3) Grey melange : 85 % cotton / 15 % viscose, (4) Charcoal melange : 60 % cotton / 40 % polyester, (5) Heather oxblood : 60 % cotton / 40 % polyester, (6) Heather khaki : 60 % cotton / 40 % polyester, (7) Heather denim : 60 % cotton / 40 % polyester. About sizes matching, please refer to the size chart in the product documentation section.",
    gender: "MEN",
    material: "Cotton",
    printable: "yes",
    variants: [
      {
        variant_id: "10215988",
        sku: "S00553-DB-S",
        release_date: "2023-08-25",
        product_proposition_category: "375",
        category_level1: "Textile SOLO Group",
        category_level2: "Textile Category",
        category_level3: "T-shirts",
        color_description: "Deep Black",
        color_group: "Black",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "3660731132745",
        size_textile: "S",
        color_code: "DB",
        pms_color: "BLACK C",
        digital_assets: [
          {
            url: "/assets/img/apparels/men/round-neck/DB-with-logo.jpg",
            url_highress:
              "/assets/img/apparels/men/round-neck/DB-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s00553-db.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s00553-db.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s00553-db-back.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s00553-db-back.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s00553-db-side.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s00553-db-side.jpg",
            type: "image",
            subtype: "item_picture_side",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s00553-db-print.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s00553-db-print.jpg",
            type: "image",
            subtype: "item_picture_printed",
          },
        ],
      },
    ],
  },
  {
    main_category_name: "Men",
    category_name: "Round-Neck-TShirt-Male",
    master_code: "S00553",
    master_id: "40007991",
    type_of_products: "textile",
    commodity_code: "6109 1000",
    number_of_print_positions: "5",
    country_of_origin: "BD",
    brand: "Sol's",
    product_name: "REGENT FIT",
    category_code: "MOBTEX_TSHMEN",
    product_class: "T-shirt",
    dimensions: "60 X 39 X 32 CM",
    length: "0",
    length_unit: "cm",
    width: "0",
    width_unit: "cm",
    height: "0",
    height_unit: "cm",
    volume: "0.63",
    volume_unit: "cdm3",
    gross_weight: "0.141",
    gross_weight_unit: "kg",
    net_weight: "0.132",
    net_weight_unit: "kg",
    inner_carton_quantity: "10",
    outer_carton_quantity: "100",
    carton_length: "0.52",
    carton_length_unit: "m",
    carton_width: "0.38",
    carton_width_unit: "m",
    carton_height: "0.32",
    carton_height_unit: "m",
    carton_volume: "0.063",
    carton_volume_unit: "m3",
    carton_gross_weight: "14.06",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/size-chart/s00553-sizechart.pdf",
        type: "document",
        subtype: "size_chart",
      },
      {
        url: "https://cdn1.midocean.com/document/oeko-tex-certificate/s00553-oekotex.pdf",
        type: "document",
        subtype: "oeko-tex_certificate",
      },
      {
        url: "https://cdn1.midocean.com/document/peta-certificate/s00553-peta.pdf",
        type: "document",
        subtype: "peta_certificate",
      },
    ],
    short_description: "REGENT F MEN T-SHIRT 150g",
    long_description:
      "SOL'S REGENT FIT Men's Round Neck Close Fitting T-shirt, 100% semi- combed Ringspun cotton Jersey 150, Tape inside collar, Elastane rib round collar, Short sleeves, Cut and sewn, Fitted cut, (1) Heather pink : 60 % cotton / 40 % polyester, (2) Heather sky : 60 % cotton / 40 % polyester, (3) Grey melange : 85 % cotton / 15 % viscose, (4) Charcoal melange : 60 % cotton / 40 % polyester, (5) Heather oxblood : 60 % cotton / 40 % polyester, (6) Heather khaki : 60 % cotton / 40 % polyester, (7) Heather denim : 60 % cotton / 40 % polyester. About sizes matching, please refer to the size chart in the product documentation section.",
    gender: "MEN",
    material: "Cotton",
    printable: "yes",
    variants: [
      {
        variant_id: "10216006",
        sku: "S00553-FN-S",
        release_date: "2023-08-25",
        product_proposition_category: "375",
        category_level1: "Textile SOLO Group",
        category_level2: "Textile Category",
        category_level3: "T-shirts",
        color_description: "French Navy",
        color_group: "Blue",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "3660731132899",
        size_textile: "S",
        color_code: "FN",
        pms_color: "539C",
        digital_assets: [
          {
            url: "/assets/img/apparels/men/round-neck/FN-with-logo.jpg",
            url_highress:
              "/assets/img/apparels/men/round-neck/FN-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s00553-fn.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s00553-fn.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s00553-fn-back.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s00553-fn-back.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s00553-fn-side.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s00553-fn-side.jpg",
            type: "image",
            subtype: "item_picture_side",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s00553-fn-print.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s00553-fn-print.jpg",
            type: "image",
            subtype: "item_picture_printed",
          },
        ],
      },
    ],
  },
  {
    main_category_name: "Men",
    category_name: "Round-Neck-TShirt-Male",
    master_code: "S00553",
    master_id: "40007991",
    type_of_products: "textile",
    commodity_code: "6109 1000",
    number_of_print_positions: "5",
    country_of_origin: "BD",
    brand: "Sol's",
    product_name: "REGENT FIT",
    category_code: "MOBTEX_TSHMEN",
    product_class: "T-shirt",
    dimensions: "60 X 39 X 32 CM",
    length: "0",
    length_unit: "cm",
    width: "0",
    width_unit: "cm",
    height: "0",
    height_unit: "cm",
    volume: "0.63",
    volume_unit: "cdm3",
    gross_weight: "0.141",
    gross_weight_unit: "kg",
    net_weight: "0.132",
    net_weight_unit: "kg",
    inner_carton_quantity: "10",
    outer_carton_quantity: "100",
    carton_length: "0.52",
    carton_length_unit: "m",
    carton_width: "0.38",
    carton_width_unit: "m",
    carton_height: "0.32",
    carton_height_unit: "m",
    carton_volume: "0.063",
    carton_volume_unit: "m3",
    carton_gross_weight: "14.06",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/size-chart/s00553-sizechart.pdf",
        type: "document",
        subtype: "size_chart",
      },
      {
        url: "https://cdn1.midocean.com/document/oeko-tex-certificate/s00553-oekotex.pdf",
        type: "document",
        subtype: "oeko-tex_certificate",
      },
      {
        url: "https://cdn1.midocean.com/document/peta-certificate/s00553-peta.pdf",
        type: "document",
        subtype: "peta_certificate",
      },
    ],
    short_description: "REGENT F MEN T-SHIRT 150g",
    long_description:
      "SOL'S REGENT FIT Men's Round Neck Close Fitting T-shirt, 100% semi- combed Ringspun cotton Jersey 150, Tape inside collar, Elastane rib round collar, Short sleeves, Cut and sewn, Fitted cut, (1) Heather pink : 60 % cotton / 40 % polyester, (2) Heather sky : 60 % cotton / 40 % polyester, (3) Grey melange : 85 % cotton / 15 % viscose, (4) Charcoal melange : 60 % cotton / 40 % polyester, (5) Heather oxblood : 60 % cotton / 40 % polyester, (6) Heather khaki : 60 % cotton / 40 % polyester, (7) Heather denim : 60 % cotton / 40 % polyester. About sizes matching, please refer to the size chart in the product documentation section.",
    gender: "MEN",
    material: "Cotton",
    printable: "yes",
    variants: [
      {
        variant_id: "10216090",
        sku: "S00553-WH-S",
        release_date: "2023-08-25",
        product_proposition_category: "375",
        category_level1: "Textile SOLO Group",
        category_level2: "Textile Category",
        category_level3: "T-shirts",
        color_description: "White",
        color_group: "White",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "3660731132608",
        size_textile: "S",
        color_code: "WH",
        pms_color: "WHITE",
        digital_assets: [
          {
            url: "/assets/img/apparels/men/round-neck/WH-with-logo.jpg",
            url_highress:
              "/assets/img/apparels/men/round-neck/WH-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s00553-wh.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s00553-wh.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s00553-wh-back.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s00553-wh-back.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s00553-wh-side.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s00553-wh-side.jpg",
            type: "image",
            subtype: "item_picture_side",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s00553-wh-print.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s00553-wh-print.jpg",
            type: "image",
            subtype: "item_picture_printed",
          },
        ],
      },
    ],
  },
  {
    main_category_name: "Men",
    category_name: "Caps",
    master_code: "KC1447",
    master_id: "40000941",
    type_of_products: "stock",
    commodity_code: "6505 0030",
    number_of_print_positions: "5",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "GLOP CAP",
    category_code: "MOBL&G_CPSBAS",
    product_class: "Baseball caps",
    dimensions: "25X16X15CM",
    length: "25",
    length_unit: "cm",
    width: "16",
    width_unit: "cm",
    height: "15",
    height_unit: "cm",
    volume: "0.514",
    volume_unit: "cdm3",
    gross_weight: "0.056",
    gross_weight_unit: "kg",
    net_weight: "0.052",
    net_weight_unit: "kg",
    inner_carton_quantity: "50",
    outer_carton_quantity: "200",
    carton_length: "0.6",
    carton_length_unit: "m",
    carton_width: "0.38",
    carton_width_unit: "m",
    carton_height: "0.45",
    carton_height_unit: "m",
    carton_volume: "0.103",
    carton_volume_unit: "m3",
    carton_gross_weight: "12.16",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/kc1447-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/kc1447-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/test-reach/kc1447-test-reach.pdf",
        type: "document",
        subtype: "test_reach",
      },
    ],
    short_description: "Baseball cap",
    long_description:
      "5 panel baseball cap in cotton,160 gr/m² twill with adjustable plastic snap  closure. 2 metal eyelets with matching colour plating. Size 7 1/4.",
    material: "Cotton",
    green: "yes",
    top_seller: "yes",
    printable: "yes",
    variants: [
      {
        variant_id: "10100169",
        sku: "KC1447-03",
        release_date: "2015-01-01",
        product_proposition_category: "361",
        category_level1: "Apparel & Accessories",
        category_level2: "Head gear",
        category_level3: "Caps",
        color_description: "Black",
        color_group: "Black",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941015081",
        color_code: "03",
        pms_color: "BLACK",
        digital_assets: [
          {
            url: "/assets/img/apparels/men/caps/KC-03-with-logo.jpg",
            url_highress: "/assets/img/apparels/men/caps/KC-03-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/kc1447-03.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/kc1447-03.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/kc1447-03-back.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/kc1447-03-back.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
        ],
      },
    ],
  },
  {
    main_category_name: "Men",
    category_name: "Caps",
    master_code: "KC1447",
    master_id: "40000941",
    type_of_products: "stock",
    commodity_code: "6505 0030",
    number_of_print_positions: "5",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "GLOP CAP",
    category_code: "MOBL&G_CPSBAS",
    product_class: "Baseball caps",
    dimensions: "25X16X15CM",
    length: "25",
    length_unit: "cm",
    width: "16",
    width_unit: "cm",
    height: "15",
    height_unit: "cm",
    volume: "0.514",
    volume_unit: "cdm3",
    gross_weight: "0.056",
    gross_weight_unit: "kg",
    net_weight: "0.052",
    net_weight_unit: "kg",
    inner_carton_quantity: "50",
    outer_carton_quantity: "200",
    carton_length: "0.6",
    carton_length_unit: "m",
    carton_width: "0.38",
    carton_width_unit: "m",
    carton_height: "0.45",
    carton_height_unit: "m",
    carton_volume: "0.103",
    carton_volume_unit: "m3",
    carton_gross_weight: "12.16",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/kc1447-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/kc1447-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/test-reach/kc1447-test-reach.pdf",
        type: "document",
        subtype: "test_reach",
      },
    ],
    short_description: "Baseball cap",
    long_description:
      "5 panel baseball cap in cotton,160 gr/m² twill with adjustable plastic snap  closure. 2 metal eyelets with matching colour plating. Size 7 1/4.",
    material: "Cotton",
    green: "yes",
    top_seller: "yes",
    printable: "yes",
    variants: [
      {
        variant_id: "10100179",
        sku: "KC1447-06",
        release_date: "2015-01-01",
        product_proposition_category: "361",
        category_level1: "Apparel & Accessories",
        category_level2: "Head gear",
        category_level3: "Caps",
        color_description: "White",
        color_group: "White",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941015135",
        color_code: "06",
        pms_color: "WHITE",
        digital_assets: [
          {
            url: "/assets/img/apparels/men/caps/KC-06-with-logo.jpg",
            url_highress: "/assets/img/apparels/men/caps/KC-06-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/kc1447-06.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/kc1447-06.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/kc1447-06-back.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/kc1447-06-back.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
        ],
      },
    ],
  },
  {
    main_category_name: "Women",
    category_name: "Women-Polo-Tshirts",
    master_code: "S11338",
    master_id: "40008018",
    type_of_products: "textile",
    commodity_code: "6106 1000",
    number_of_print_positions: "5",
    country_of_origin: "BD",
    brand: "Sol's",
    product_name: "PASSION",
    category_code: "MOBTEX_PSHWMN",
    product_class: "Polo shirt",
    dimensions: "52 X 36 X 27 CM",
    length: "0",
    length_unit: "cm",
    width: "0",
    width_unit: "cm",
    height: "0",
    height_unit: "cm",
    volume: "0.78",
    volume_unit: "cdm3",
    gross_weight: "0.156",
    gross_weight_unit: "kg",
    net_weight: "0.134",
    net_weight_unit: "kg",
    inner_carton_quantity: "5",
    outer_carton_quantity: "50",
    carton_length: "0.45",
    carton_length_unit: "m",
    carton_width: "0.32",
    carton_width_unit: "m",
    carton_height: "0.27",
    carton_height_unit: "m",
    carton_volume: "0.039",
    carton_volume_unit: "m3",
    carton_gross_weight: "7.775",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/size-chart/s11338-sizechart.pdf",
        type: "document",
        subtype: "size_chart",
      },
      {
        url: "https://cdn1.midocean.com/document/oeko-tex-certificate/s11338-oekotex.pdf",
        type: "document",
        subtype: "oeko-tex_certificate",
      },
      {
        url: "https://cdn1.midocean.com/document/peta-certificate/s11338-peta.pdf",
        type: "document",
        subtype: "peta_certificate",
      },
    ],
    short_description: "PASSION WOMEN POLO 170g",
    long_description:
      "SOL'S PASSION, women's polo shirt, 100% combed ringspun cotton piqué 170, rib collar and cuffs, reinforcing tape on neck, short sleeves, fitted cut, tone on tone 4 button placket, straight hem with side slits, cut and sewn, spare button on the inside seam, (1) grey melange : 85 % cotton / 15 % viscose. About sizes matching, please refer to the size chart in the product documentation section.",
    gender: "WOMEN",
    material: "Cotton",
    printable: "yes",
    variants: [
      {
        variant_id: "10218081",
        sku: "S11338-WH-S",
        release_date: "2023-08-25",
        product_proposition_category: "378",
        category_level1: "Textile SOLO Group",
        category_level2: "Textile Category",
        category_level3: "Polo's",
        color_description: "White",
        color_group: "White",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "3660734071591",
        size_textile: "S",
        color_code: "WH",
        pms_color: "WHITE",
        digital_assets: [
          {
            url: "/assets/img/apparels/women/polo/WH-with-logo.jpg",
            url_highress: "/assets/img/apparels/women/polo/WH-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s11338-wh.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s11338-wh.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s11338-wh-back.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s11338-wh-back.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s11338-wh-side.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s11338-wh-side.jpg",
            type: "image",
            subtype: "item_picture_side",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s11338-wh-print.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s11338-wh-print.jpg",
            type: "image",
            subtype: "item_picture_printed",
          },
        ],
      },
    ],
  },
  {
    main_category_name: "Women",
    category_name: "Women-Polo-Tshirts",
    master_code: "S11338",
    master_id: "40008018",
    type_of_products: "textile",
    commodity_code: "6106 1000",
    number_of_print_positions: "5",
    country_of_origin: "BD",
    brand: "Sol's",
    product_name: "PASSION",
    category_code: "MOBTEX_PSHWMN",
    product_class: "Polo shirt",
    dimensions: "52 X 36 X 27 CM",
    length: "0",
    length_unit: "cm",
    width: "0",
    width_unit: "cm",
    height: "0",
    height_unit: "cm",
    volume: "0.78",
    volume_unit: "cdm3",
    gross_weight: "0.156",
    gross_weight_unit: "kg",
    net_weight: "0.134",
    net_weight_unit: "kg",
    inner_carton_quantity: "5",
    outer_carton_quantity: "50",
    carton_length: "0.45",
    carton_length_unit: "m",
    carton_width: "0.32",
    carton_width_unit: "m",
    carton_height: "0.27",
    carton_height_unit: "m",
    carton_volume: "0.039",
    carton_volume_unit: "m3",
    carton_gross_weight: "7.775",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/size-chart/s11338-sizechart.pdf",
        type: "document",
        subtype: "size_chart",
      },
      {
        url: "https://cdn1.midocean.com/document/oeko-tex-certificate/s11338-oekotex.pdf",
        type: "document",
        subtype: "oeko-tex_certificate",
      },
      {
        url: "https://cdn1.midocean.com/document/peta-certificate/s11338-peta.pdf",
        type: "document",
        subtype: "peta_certificate",
      },
    ],
    short_description: "PASSION WOMEN POLO 170g",
    long_description:
      "SOL'S PASSION, women's polo shirt, 100% combed ringspun cotton piqué 170, rib collar and cuffs, reinforcing tape on neck, short sleeves, fitted cut, tone on tone 4 button placket, straight hem with side slits, cut and sewn, spare button on the inside seam, (1) grey melange : 85 % cotton / 15 % viscose. About sizes matching, please refer to the size chart in the product documentation section.",
    gender: "WOMEN",
    material: "Cotton",
    printable: "yes",
    variants: [
      {
        variant_id: "10218001",
        sku: "S11338-BK-S",
        release_date: "2023-08-25",
        product_proposition_category: "378",
        category_level1: "Textile SOLO Group",
        category_level2: "Textile Category",
        category_level3: "Polo's",
        color_description: "Black",
        color_group: "Black",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "3660734071942",
        size_textile: "S",
        color_code: "BK",
        pms_color: "426C",
        digital_assets: [
          {
            url: "/assets/img/apparels/women/polo/BK-with-logo.jpg",
            url_highress: "/assets/img/apparels/women/polo/BK-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s11338-bk.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s11338-bk.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s11338-bk-back.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s11338-bk-back.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s11338-bk-side.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s11338-bk-side.jpg",
            type: "image",
            subtype: "item_picture_side",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s11338-bk-print.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s11338-bk-print.jpg",
            type: "image",
            subtype: "item_picture_printed",
          },
        ],
      },
    ],
  },
  {
    main_category_name: "Women",
    category_name: "Women-Polo-Tshirts",
    master_code: "S11338",
    master_id: "40008018",
    type_of_products: "textile",
    commodity_code: "6106 1000",
    number_of_print_positions: "5",
    country_of_origin: "BD",
    brand: "Sol's",
    product_name: "PASSION",
    category_code: "MOBTEX_PSHWMN",
    product_class: "Polo shirt",
    dimensions: "52 X 36 X 27 CM",
    length: "0",
    length_unit: "cm",
    width: "0",
    width_unit: "cm",
    height: "0",
    height_unit: "cm",
    volume: "0.78",
    volume_unit: "cdm3",
    gross_weight: "0.156",
    gross_weight_unit: "kg",
    net_weight: "0.134",
    net_weight_unit: "kg",
    inner_carton_quantity: "5",
    outer_carton_quantity: "50",
    carton_length: "0.45",
    carton_length_unit: "m",
    carton_width: "0.32",
    carton_width_unit: "m",
    carton_height: "0.27",
    carton_height_unit: "m",
    carton_volume: "0.039",
    carton_volume_unit: "m3",
    carton_gross_weight: "7.775",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/size-chart/s11338-sizechart.pdf",
        type: "document",
        subtype: "size_chart",
      },
      {
        url: "https://cdn1.midocean.com/document/oeko-tex-certificate/s11338-oekotex.pdf",
        type: "document",
        subtype: "oeko-tex_certificate",
      },
      {
        url: "https://cdn1.midocean.com/document/peta-certificate/s11338-peta.pdf",
        type: "document",
        subtype: "peta_certificate",
      },
    ],
    short_description: "PASSION WOMEN POLO 170g",
    long_description:
      "SOL'S PASSION, women's polo shirt, 100% combed ringspun cotton piqué 170, rib collar and cuffs, reinforcing tape on neck, short sleeves, fitted cut, tone on tone 4 button placket, straight hem with side slits, cut and sewn, spare button on the inside seam, (1) grey melange : 85 % cotton / 15 % viscose. About sizes matching, please refer to the size chart in the product documentation section.",
    gender: "WOMEN",
    material: "Cotton",
    printable: "yes",
    variants: [
      {
        variant_id: "10218046",
        sku: "S11338-NY-S",
        release_date: "2023-08-25",
        product_proposition_category: "378",
        category_level1: "Textile SOLO Group",
        category_level2: "Textile Category",
        category_level3: "Polo's",
        color_description: "Navy",
        color_group: "Blue",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "3660734071898",
        size_textile: "S",
        color_code: "NY",
        pms_color: "296C",
        digital_assets: [
          {
            url: "/assets/img/apparels/women/polo/NY-with-logo.jpg",
            url_highress: "/assets/img/apparels/women/polo/NY-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s11338-ny.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s11338-ny.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s11338-ny-back.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s11338-ny-back.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s11338-ny-side.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s11338-ny-side.jpg",
            type: "image",
            subtype: "item_picture_side",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s11338-ny-print.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s11338-ny-print.jpg",
            type: "image",
            subtype: "item_picture_printed",
          },
        ],
      },
    ],
  },
  {
    main_category_name: "Women",
    category_name: "Women-Shirts",
    master_code: "S17015",
    master_id: "40008728",
    type_of_products: "textile",
    commodity_code: "6206 3000",
    number_of_print_positions: "3",
    country_of_origin: "BD",
    brand: "Sol's",
    product_name: "EDEN",
    category_code: "MOBTEX_SHT",
    product_class: "Shirt",
    dimensions: "55 X 37 X 17 CM",
    length: "0",
    length_unit: "cm",
    width: "0",
    width_unit: "cm",
    height: "0",
    height_unit: "cm",
    volume: "1.55",
    volume_unit: "cdm3",
    gross_weight: "0.266",
    gross_weight_unit: "kg",
    net_weight: "0.2",
    net_weight_unit: "kg",
    inner_carton_quantity: "1",
    outer_carton_quantity: "20",
    carton_length: "0.55",
    carton_length_unit: "m",
    carton_width: "0.37",
    carton_width_unit: "m",
    carton_height: "0.15",
    carton_height_unit: "m",
    carton_volume: "0.031",
    carton_volume_unit: "m3",
    carton_gross_weight: "5.32",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/size-chart/s17015-sizechart.pdf",
        type: "document",
        subtype: "size_chart",
      },
      {
        url: "https://cdn1.midocean.com/document/oeko-tex-certificate/s17015-oekotex.pdf",
        type: "document",
        subtype: "oeko-tex_certificate",
      },
      {
        url: "https://cdn1.midocean.com/document/peta-certificate/s17015-peta.pdf",
        type: "document",
        subtype: "peta_certificate",
      },
    ],
    short_description: "EDEN women shirt 140g",
    long_description:
      "SOL'S EDEN women shirt 140g. Made out of cotton elastane, with a close-fitting cut, it provides a pleasant ironed look and comfort. Very fashionable product. Close-fitting cut. Long sleeves. Single button collar. Adjustable cuffs with 2 buttons and bound slit. 7 tone-on-tone button placket. Front and back darts. Fabric details: 97% cotton - 3% elastane. OEKO-TEX®. Only Sold with Print.",
    gender: "WOMEN",
    printable: "yes",
    variants: [
      {
        variant_id: "10223984",
        sku: "S17015-WH-S",
        release_date: "2023-08-25",
        product_proposition_category: "751",
        category_level1: "Textile SOLO Group",
        category_level2: "Brand",
        category_level3: "SOL'S",
        color_description: "White",
        color_group: "White",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "3660734071119",
        size_textile: "S",
        color_code: "WH",
        pms_color: "WHITE",
        digital_assets: [
          {
            url: "/assets/img/apparels/women/shirts/WH-with-logo.jpg",
            url_highress: "/assets/img/apparels/women/shirts/WH-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s17015-wh.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s17015-wh.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s17015-wh-back.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s17015-wh-back.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s17015-wh-side.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s17015-wh-side.jpg",
            type: "image",
            subtype: "item_picture_side",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s17015-wh-print.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s17015-wh-print.jpg",
            type: "image",
            subtype: "item_picture_printed",
          },
        ],
      },
    ],
  },
  {
    main_category_name: "Women",
    category_name: "Women-Shirts",
    master_code: "S17015",
    master_id: "40008728",
    type_of_products: "textile",
    commodity_code: "6206 3000",
    number_of_print_positions: "3",
    country_of_origin: "BD",
    brand: "Sol's",
    product_name: "EDEN",
    category_code: "MOBTEX_SHT",
    product_class: "Shirt",
    dimensions: "55 X 37 X 17 CM",
    length: "0",
    length_unit: "cm",
    width: "0",
    width_unit: "cm",
    height: "0",
    height_unit: "cm",
    volume: "1.55",
    volume_unit: "cdm3",
    gross_weight: "0.266",
    gross_weight_unit: "kg",
    net_weight: "0.2",
    net_weight_unit: "kg",
    inner_carton_quantity: "1",
    outer_carton_quantity: "20",
    carton_length: "0.55",
    carton_length_unit: "m",
    carton_width: "0.37",
    carton_width_unit: "m",
    carton_height: "0.15",
    carton_height_unit: "m",
    carton_volume: "0.031",
    carton_volume_unit: "m3",
    carton_gross_weight: "5.32",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/size-chart/s17015-sizechart.pdf",
        type: "document",
        subtype: "size_chart",
      },
      {
        url: "https://cdn1.midocean.com/document/oeko-tex-certificate/s17015-oekotex.pdf",
        type: "document",
        subtype: "oeko-tex_certificate",
      },
      {
        url: "https://cdn1.midocean.com/document/peta-certificate/s17015-peta.pdf",
        type: "document",
        subtype: "peta_certificate",
      },
    ],
    short_description: "EDEN women shirt 140g",
    long_description:
      "SOL'S EDEN women shirt 140g. Made out of cotton elastane, with a close-fitting cut, it provides a pleasant ironed look and comfort. Very fashionable product. Close-fitting cut. Long sleeves. Single button collar. Adjustable cuffs with 2 buttons and bound slit. 7 tone-on-tone button placket. Front and back darts. Fabric details: 97% cotton - 3% elastane. OEKO-TEX®. Only Sold with Print.",
    gender: "WOMEN",
    printable: "yes",
    variants: [
      {
        variant_id: "10223977",
        sku: "S17015-BK-S",
        release_date: "2023-08-25",
        product_proposition_category: "751",
        category_level1: "Textile SOLO Group",
        category_level2: "Brand",
        category_level3: "SOL'S",
        color_description: "Black",
        color_group: "Black",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "3660734071294",
        size_textile: "S",
        color_code: "BK",
        pms_color: "426C",
        digital_assets: [
          {
            url: "/assets/img/apparels/women/shirts/BK-with-logo.jpg",
            url_highress: "/assets/img/apparels/women/shirts/BK-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s17015-bk.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s17015-bk.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s17015-bk-back.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s17015-bk-back.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s17015-bk-side.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s17015-bk-side.jpg",
            type: "image",
            subtype: "item_picture_side",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s17015-bk-print.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s17015-bk-print.jpg",
            type: "image",
            subtype: "item_picture_printed",
          },
        ],
      },
    ],
  },
  {
    main_category_name: "Women",
    category_name: "Women-Hoodded-Sweat-Shirt",
    master_code: "S03106",
    master_id: "40009057",
    type_of_products: "textile",
    commodity_code: "6110 2099",
    number_of_print_positions: "5",
    country_of_origin: "BD",
    brand: "Sol's",
    product_name: "SPIKE WOMEN",
    category_code: "MOBTEX_SWJWMN",
    product_class: "Sweat jacket",
    dimensions: "54 X 36 X 51 CM",
    length: "0",
    length_unit: "cm",
    width: "0",
    width_unit: "cm",
    height: "0",
    height_unit: "cm",
    volume: "3.68",
    volume_unit: "cdm3",
    gross_weight: "0.548",
    gross_weight_unit: "kg",
    net_weight: "0.495",
    net_weight_unit: "kg",
    inner_carton_quantity: "5",
    outer_carton_quantity: "25",
    carton_length: "0.51",
    carton_length_unit: "m",
    carton_width: "0.36",
    carton_width_unit: "m",
    carton_height: "0.5",
    carton_height_unit: "m",
    carton_volume: "0.092",
    carton_volume_unit: "m3",
    carton_gross_weight: "13.693",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/size-chart/s03106-sizechart.pdf",
        type: "document",
        subtype: "size_chart",
      },
      {
        url: "https://cdn1.midocean.com/document/oeko-tex-certificate/s03106-oekotex.pdf",
        type: "document",
        subtype: "oeko-tex_certificate",
      },
      {
        url: "https://cdn1.midocean.com/document/peta-certificate/s03106-peta.pdf",
        type: "document",
        subtype: "peta_certificate",
      },
    ],
    short_description: "SPIKE HOOD WOMEN 280gr",
    long_description:
      "SOL'S SPIKE WOMEN. Women's zip hoodie, 80% ring spun cotton - 20% polyester Brushed lining, Outside: 100% cotton FLEECE 280 LSF (Low Shrinkage Fabric), Set-in sleeves, 2 front pouch pockets, Cut and sewn, Lined hood with tone-on-tone drawcord, 2x2 ribbed cuffs and hem. Half-moon inside collar, Taped neck, YKK zip. OEKO-TEX®. Only sold with print.",
    gender: "WOMEN",
    printable: "yes",
    variants: [
      {
        variant_id: "10226107",
        sku: "S03106-WH-S",
        release_date: "2023-08-25",
        product_proposition_category: "379",
        category_level1: "Textile SOLO Group",
        category_level2: "Textile Category",
        category_level3: "Sweat shirts",
        color_description: "White",
        color_group: "White",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "3609371002841",
        size_textile: "S",
        color_code: "WH",
        pms_color: "WHITE",
        digital_assets: [
          {
            url: "/assets/img/apparels/women/hoddie/WH-with-logo.jpg",
            url_highress: "/assets/img/apparels/women/hoddie/WH-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s03106-wh.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s03106-wh.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s03106-wh-back.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s03106-wh-back.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s03106-wh-side.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s03106-wh-side.jpg",
            type: "image",
            subtype: "item_picture_side",
          },
        ],
      },
    ],
  },
  {
    main_category_name: "Women",
    category_name: "Women-Hoodded-Sweat-Shirt",
    master_code: "S03106",
    master_id: "40009057",
    type_of_products: "textile",
    commodity_code: "6110 2099",
    number_of_print_positions: "5",
    country_of_origin: "BD",
    brand: "Sol's",
    product_name: "SPIKE WOMEN",
    category_code: "MOBTEX_SWJWMN",
    product_class: "Sweat jacket",
    dimensions: "54 X 36 X 51 CM",
    length: "0",
    length_unit: "cm",
    width: "0",
    width_unit: "cm",
    height: "0",
    height_unit: "cm",
    volume: "3.68",
    volume_unit: "cdm3",
    gross_weight: "0.548",
    gross_weight_unit: "kg",
    net_weight: "0.495",
    net_weight_unit: "kg",
    inner_carton_quantity: "5",
    outer_carton_quantity: "25",
    carton_length: "0.51",
    carton_length_unit: "m",
    carton_width: "0.36",
    carton_width_unit: "m",
    carton_height: "0.5",
    carton_height_unit: "m",
    carton_volume: "0.092",
    carton_volume_unit: "m3",
    carton_gross_weight: "13.693",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/size-chart/s03106-sizechart.pdf",
        type: "document",
        subtype: "size_chart",
      },
      {
        url: "https://cdn1.midocean.com/document/oeko-tex-certificate/s03106-oekotex.pdf",
        type: "document",
        subtype: "oeko-tex_certificate",
      },
      {
        url: "https://cdn1.midocean.com/document/peta-certificate/s03106-peta.pdf",
        type: "document",
        subtype: "peta_certificate",
      },
    ],
    short_description: "SPIKE HOOD WOMEN 280gr",
    long_description:
      "SOL'S SPIKE WOMEN. Women's zip hoodie, 80% ring spun cotton - 20% polyester Brushed lining, Outside: 100% cotton FLEECE 280 LSF (Low Shrinkage Fabric), Set-in sleeves, 2 front pouch pockets, Cut and sewn, Lined hood with tone-on-tone drawcord, 2x2 ribbed cuffs and hem. Half-moon inside collar, Taped neck, YKK zip. OEKO-TEX®. Only sold with print.",
    gender: "WOMEN",
    printable: "yes",
    variants: [
      {
        variant_id: "10226083",
        sku: "S03106-BK-S",
        release_date: "2023-08-25",
        product_proposition_category: "379",
        category_level1: "Textile SOLO Group",
        category_level2: "Textile Category",
        category_level3: "Sweat shirts",
        color_description: "Black",
        color_group: "Black",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "3609371002148",
        size_textile: "S",
        color_code: "BK",
        pms_color: "426C",
        digital_assets: [
          {
            url: "/assets/img/apparels/women/hoddie/BK-with-logo.jpg",
            url_highress: "/assets/img/apparels/women/hoddie/BK-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s03106-bk.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s03106-bk.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s03106-bk-back.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s03106-bk-back.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s03106-bk-side.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s03106-bk-side.jpg",
            type: "image",
            subtype: "item_picture_side",
          },
        ],
      },
    ],
  },
  {
    main_category_name: "Women",
    category_name: "Round-Neck-TShirt-Female",
    master_code: "S11386",
    master_id: "40008025",
    type_of_products: "textile",
    commodity_code: "6109 1000",
    number_of_print_positions: "5",
    country_of_origin: "BD",
    brand: "Sol's",
    product_name: "MISS",
    category_code: "MOBTEX_TSHWMN",
    product_class: "T-shirt",
    dimensions: "44 X 31 X 18 CM",
    length: "0",
    length_unit: "cm",
    width: "0",
    width_unit: "cm",
    height: "0",
    height_unit: "cm",
    volume: "0.5",
    volume_unit: "cdm3",
    gross_weight: "0.109",
    gross_weight_unit: "kg",
    net_weight: "0.092",
    net_weight_unit: "kg",
    inner_carton_quantity: "10",
    outer_carton_quantity: "50",
    carton_length: "0.43",
    carton_length_unit: "m",
    carton_width: "0.32",
    carton_width_unit: "m",
    carton_height: "0.18",
    carton_height_unit: "m",
    carton_volume: "0.025",
    carton_volume_unit: "m3",
    carton_gross_weight: "5.44",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/size-chart/s11386-sizechart.pdf",
        type: "document",
        subtype: "size_chart",
      },
      {
        url: "https://cdn1.midocean.com/document/oeko-tex-certificate/s11386-oekotex.pdf",
        type: "document",
        subtype: "oeko-tex_certificate",
      },
      {
        url: "https://cdn1.midocean.com/document/peta-certificate/s11386-peta.pdf",
        type: "document",
        subtype: "peta_certificate",
      },
    ],
    short_description: "MISS WOMEN T-SHIRT 150g",
    long_description:
      "SOL'S MISS Women's T-shirt comes in 24 colours and with its fitted cut is extremely popular across the European market. Reinforced taped neck, modern fitted cut with sewn fit with side seam and slim collar. Fabric details: 150g/m² in 100% semi-combed ringspun cotton. OEKO-TEX®. Only sold with print",
    gender: "WOMEN",
    material: "Cotton",
    printable: "yes",
    variants: [
      {
        variant_id: "10219067",
        sku: "S11386-RD-S",
        release_date: "2023-08-25",
        product_proposition_category: "375",
        category_level1: "Textile SOLO Group",
        category_level2: "Textile Category",
        category_level3: "T-shirts",
        color_description: "Red",
        color_group: "Red",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "3660734039799",
        size_textile: "S",
        color_code: "RD",
        pms_color: "200C",
        digital_assets: [
          {
            url: "/assets/img/apparels/women/round-neck/RD-with-logo.jpg",
            url_highress:
              "/assets/img/apparels/women/round-neck/RD-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s11386-rd.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s11386-rd.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s11386-rd-back.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s11386-rd-back.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s11386-rd-side.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s11386-rd-side.jpg",
            type: "image",
            subtype: "item_picture_side",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s11386-rd-print.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s11386-rd-print.jpg",
            type: "image",
            subtype: "item_picture_printed",
          },
        ],
      },
    ],
  },
  {
    main_category_name: "Women",
    category_name: "Round-Neck-TShirt-Female",
    master_code: "S11386",
    master_id: "40008025",
    type_of_products: "textile",
    commodity_code: "6109 1000",
    number_of_print_positions: "5",
    country_of_origin: "BD",
    brand: "Sol's",
    product_name: "MISS",
    category_code: "MOBTEX_TSHWMN",
    product_class: "T-shirt",
    dimensions: "44 X 31 X 18 CM",
    length: "0",
    length_unit: "cm",
    width: "0",
    width_unit: "cm",
    height: "0",
    height_unit: "cm",
    volume: "0.5",
    volume_unit: "cdm3",
    gross_weight: "0.109",
    gross_weight_unit: "kg",
    net_weight: "0.092",
    net_weight_unit: "kg",
    inner_carton_quantity: "10",
    outer_carton_quantity: "50",
    carton_length: "0.43",
    carton_length_unit: "m",
    carton_width: "0.32",
    carton_width_unit: "m",
    carton_height: "0.18",
    carton_height_unit: "m",
    carton_volume: "0.025",
    carton_volume_unit: "m3",
    carton_gross_weight: "5.44",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/size-chart/s11386-sizechart.pdf",
        type: "document",
        subtype: "size_chart",
      },
      {
        url: "https://cdn1.midocean.com/document/oeko-tex-certificate/s11386-oekotex.pdf",
        type: "document",
        subtype: "oeko-tex_certificate",
      },
      {
        url: "https://cdn1.midocean.com/document/peta-certificate/s11386-peta.pdf",
        type: "document",
        subtype: "peta_certificate",
      },
    ],
    short_description: "MISS WOMEN T-SHIRT 150g",
    long_description:
      "SOL'S MISS Women's T-shirt comes in 24 colours and with its fitted cut is extremely popular across the European market. Reinforced taped neck, modern fitted cut with sewn fit with side seam and slim collar. Fabric details: 150g/m² in 100% semi-combed ringspun cotton. OEKO-TEX®. Only sold with print",
    gender: "WOMEN",
    material: "Cotton",
    printable: "yes",
    variants: [
      {
        variant_id: "10219037",
        sku: "S11386-FU-S",
        release_date: "2023-08-25",
        product_proposition_category: "375",
        category_level1: "Textile SOLO Group",
        category_level2: "Textile Category",
        category_level3: "T-shirts",
        color_description: "Fuchsia",
        color_group: "Pink",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "3660734054648",
        size_textile: "S",
        color_code: "FU",
        pms_color: "227C",
        digital_assets: [
          {
            url: "/assets/img/apparels/women/round-neck/FU-with-logo.jpg",
            url_highress:
              "/assets/img/apparels/women/round-neck/FU-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s11386-fu.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s11386-fu.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s11386-fu-back.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s11386-fu-back.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s11386-fu-side.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s11386-fu-side.jpg",
            type: "image",
            subtype: "item_picture_side",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s11386-fu-print.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s11386-fu-print.jpg",
            type: "image",
            subtype: "item_picture_printed",
          },
        ],
      },
    ],
  },
  {
    main_category_name: "Women",
    category_name: "Round-Neck-TShirt-Female",
    master_code: "S11386",
    master_id: "40008025",
    type_of_products: "textile",
    commodity_code: "6109 1000",
    number_of_print_positions: "5",
    country_of_origin: "BD",
    brand: "Sol's",
    product_name: "MISS",
    category_code: "MOBTEX_TSHWMN",
    product_class: "T-shirt",
    dimensions: "44 X 31 X 18 CM",
    length: "0",
    length_unit: "cm",
    width: "0",
    width_unit: "cm",
    height: "0",
    height_unit: "cm",
    volume: "0.5",
    volume_unit: "cdm3",
    gross_weight: "0.109",
    gross_weight_unit: "kg",
    net_weight: "0.092",
    net_weight_unit: "kg",
    inner_carton_quantity: "10",
    outer_carton_quantity: "50",
    carton_length: "0.43",
    carton_length_unit: "m",
    carton_width: "0.32",
    carton_width_unit: "m",
    carton_height: "0.18",
    carton_height_unit: "m",
    carton_volume: "0.025",
    carton_volume_unit: "m3",
    carton_gross_weight: "5.44",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/size-chart/s11386-sizechart.pdf",
        type: "document",
        subtype: "size_chart",
      },
      {
        url: "https://cdn1.midocean.com/document/oeko-tex-certificate/s11386-oekotex.pdf",
        type: "document",
        subtype: "oeko-tex_certificate",
      },
      {
        url: "https://cdn1.midocean.com/document/peta-certificate/s11386-peta.pdf",
        type: "document",
        subtype: "peta_certificate",
      },
    ],
    short_description: "MISS WOMEN T-SHIRT 150g",
    long_description:
      "SOL'S MISS Women's T-shirt comes in 24 colours and with its fitted cut is extremely popular across the European market. Reinforced taped neck, modern fitted cut with sewn fit with side seam and slim collar. Fabric details: 150g/m² in 100% semi-combed ringspun cotton. OEKO-TEX®. Only sold with print",
    gender: "WOMEN",
    material: "Cotton",
    printable: "yes",
    variants: [
      {
        variant_id: "10219032",
        sku: "S11386-DB-S",
        release_date: "2023-08-25",
        product_proposition_category: "375",
        category_level1: "Textile SOLO Group",
        category_level2: "Textile Category",
        category_level3: "T-shirts",
        color_description: "Deep Black",
        color_group: "Black",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "3660734076114",
        size_textile: "S",
        color_code: "DB",
        pms_color: "BLACK C",
        digital_assets: [
          {
            url: "/assets/img/apparels/women/round-neck/DB-with-logo.jpg",
            url_highress:
              "/assets/img/apparels/women/round-neck/DB-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s11386-db.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s11386-db.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s11386-db-back.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s11386-db-back.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s11386-db-side.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s11386-db-side.jpg",
            type: "image",
            subtype: "item_picture_side",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s11386-db-print.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s11386-db-print.jpg",
            type: "image",
            subtype: "item_picture_printed",
          },
        ],
      },
    ],
  },
  {
    main_category_name: "Women",
    category_name: "Round-Neck-TShirt-Female",
    master_code: "S11386",
    master_id: "40008025",
    type_of_products: "textile",
    commodity_code: "6109 1000",
    number_of_print_positions: "5",
    country_of_origin: "BD",
    brand: "Sol's",
    product_name: "MISS",
    category_code: "MOBTEX_TSHWMN",
    product_class: "T-shirt",
    dimensions: "44 X 31 X 18 CM",
    length: "0",
    length_unit: "cm",
    width: "0",
    width_unit: "cm",
    height: "0",
    height_unit: "cm",
    volume: "0.5",
    volume_unit: "cdm3",
    gross_weight: "0.109",
    gross_weight_unit: "kg",
    net_weight: "0.092",
    net_weight_unit: "kg",
    inner_carton_quantity: "10",
    outer_carton_quantity: "50",
    carton_length: "0.43",
    carton_length_unit: "m",
    carton_width: "0.32",
    carton_width_unit: "m",
    carton_height: "0.18",
    carton_height_unit: "m",
    carton_volume: "0.025",
    carton_volume_unit: "m3",
    carton_gross_weight: "5.44",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/size-chart/s11386-sizechart.pdf",
        type: "document",
        subtype: "size_chart",
      },
      {
        url: "https://cdn1.midocean.com/document/oeko-tex-certificate/s11386-oekotex.pdf",
        type: "document",
        subtype: "oeko-tex_certificate",
      },
      {
        url: "https://cdn1.midocean.com/document/peta-certificate/s11386-peta.pdf",
        type: "document",
        subtype: "peta_certificate",
      },
    ],
    short_description: "MISS WOMEN T-SHIRT 150g",
    long_description:
      "SOL'S MISS Women's T-shirt comes in 24 colours and with its fitted cut is extremely popular across the European market. Reinforced taped neck, modern fitted cut with sewn fit with side seam and slim collar. Fabric details: 150g/m² in 100% semi-combed ringspun cotton. OEKO-TEX®. Only sold with print",
    gender: "WOMEN",
    material: "Cotton",
    printable: "yes",
    variants: [
      {
        variant_id: "10219062",
        sku: "S11386-RB-S",
        release_date: "2023-08-25",
        product_proposition_category: "375",
        category_level1: "Textile SOLO Group",
        category_level2: "Textile Category",
        category_level3: "T-shirts",
        color_description: "Royal Blue",
        color_group: "Blue",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "3609374010805",
        size_textile: "S",
        color_code: "RB",
        pms_color: "7686C",
        digital_assets: [
          {
            url: "/assets/img/apparels/women/round-neck/RB-with-logo.jpg",
            url_highress:
              "/assets/img/apparels/women/round-neck/RB-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s11386-rb.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s11386-rb.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s11386-rb-back.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s11386-rb-back.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s11386-rb-side.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s11386-rb-side.jpg",
            type: "image",
            subtype: "item_picture_side",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s11386-rb-print.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s11386-rb-print.jpg",
            type: "image",
            subtype: "item_picture_printed",
          },
        ],
      },
    ],
  },
  {
    main_category_name: "Women",
    category_name: "Round-Neck-TShirt-Female",
    master_code: "S11386",
    master_id: "40008025",
    type_of_products: "textile",
    commodity_code: "6109 1000",
    number_of_print_positions: "5",
    country_of_origin: "BD",
    brand: "Sol's",
    product_name: "MISS",
    category_code: "MOBTEX_TSHWMN",
    product_class: "T-shirt",
    dimensions: "44 X 31 X 18 CM",
    length: "0",
    length_unit: "cm",
    width: "0",
    width_unit: "cm",
    height: "0",
    height_unit: "cm",
    volume: "0.5",
    volume_unit: "cdm3",
    gross_weight: "0.109",
    gross_weight_unit: "kg",
    net_weight: "0.092",
    net_weight_unit: "kg",
    inner_carton_quantity: "10",
    outer_carton_quantity: "50",
    carton_length: "0.43",
    carton_length_unit: "m",
    carton_width: "0.32",
    carton_width_unit: "m",
    carton_height: "0.18",
    carton_height_unit: "m",
    carton_volume: "0.025",
    carton_volume_unit: "m3",
    carton_gross_weight: "5.44",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/size-chart/s11386-sizechart.pdf",
        type: "document",
        subtype: "size_chart",
      },
      {
        url: "https://cdn1.midocean.com/document/oeko-tex-certificate/s11386-oekotex.pdf",
        type: "document",
        subtype: "oeko-tex_certificate",
      },
      {
        url: "https://cdn1.midocean.com/document/peta-certificate/s11386-peta.pdf",
        type: "document",
        subtype: "peta_certificate",
      },
    ],
    short_description: "MISS WOMEN T-SHIRT 150g",
    long_description:
      "SOL'S MISS Women's T-shirt comes in 24 colours and with its fitted cut is extremely popular across the European market. Reinforced taped neck, modern fitted cut with sewn fit with side seam and slim collar. Fabric details: 150g/m² in 100% semi-combed ringspun cotton. OEKO-TEX®. Only sold with print",
    gender: "WOMEN",
    material: "Cotton",
    printable: "yes",
    variants: [
      {
        variant_id: "10219052",
        sku: "S11386-NY-S",
        release_date: "2023-08-25",
        product_proposition_category: "375",
        category_level1: "Textile SOLO Group",
        category_level2: "Textile Category",
        category_level3: "T-shirts",
        color_description: "Navy",
        color_group: "Blue",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "3660734054723",
        size_textile: "S",
        color_code: "NY",
        pms_color: "296C",
        digital_assets: [
          {
            url: "/assets/img/apparels/women/round-neck/NY-with-logo.jpg",
            url_highress:
              "/assets/img/apparels/women/round-neck/NY-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s11386-ny.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s11386-ny.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s11386-ny-back.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s11386-ny-back.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s11386-ny-side.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s11386-ny-side.jpg",
            type: "image",
            subtype: "item_picture_side",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s11386-ny-print.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s11386-ny-print.jpg",
            type: "image",
            subtype: "item_picture_printed",
          },
        ],
      },
    ],
  },
  {
    main_category_name: "Women",
    category_name: "Round-Neck-TShirt-Female",
    master_code: "S11386",
    master_id: "40008025",
    type_of_products: "textile",
    commodity_code: "6109 1000",
    number_of_print_positions: "5",
    country_of_origin: "BD",
    brand: "Sol's",
    product_name: "MISS",
    category_code: "MOBTEX_TSHWMN",
    product_class: "T-shirt",
    dimensions: "44 X 31 X 18 CM",
    length: "0",
    length_unit: "cm",
    width: "0",
    width_unit: "cm",
    height: "0",
    height_unit: "cm",
    volume: "0.5",
    volume_unit: "cdm3",
    gross_weight: "0.109",
    gross_weight_unit: "kg",
    net_weight: "0.092",
    net_weight_unit: "kg",
    inner_carton_quantity: "10",
    outer_carton_quantity: "50",
    carton_length: "0.43",
    carton_length_unit: "m",
    carton_width: "0.32",
    carton_width_unit: "m",
    carton_height: "0.18",
    carton_height_unit: "m",
    carton_volume: "0.025",
    carton_volume_unit: "m3",
    carton_gross_weight: "5.44",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/size-chart/s11386-sizechart.pdf",
        type: "document",
        subtype: "size_chart",
      },
      {
        url: "https://cdn1.midocean.com/document/oeko-tex-certificate/s11386-oekotex.pdf",
        type: "document",
        subtype: "oeko-tex_certificate",
      },
      {
        url: "https://cdn1.midocean.com/document/peta-certificate/s11386-peta.pdf",
        type: "document",
        subtype: "peta_certificate",
      },
    ],
    short_description: "MISS WOMEN T-SHIRT 150g",
    long_description:
      "SOL'S MISS Women's T-shirt comes in 24 colours and with its fitted cut is extremely popular across the European market. Reinforced taped neck, modern fitted cut with sewn fit with side seam and slim collar. Fabric details: 150g/m² in 100% semi-combed ringspun cotton. OEKO-TEX®. Only sold with print",
    gender: "WOMEN",
    material: "Cotton",
    printable: "yes",
    variants: [
      {
        variant_id: "10219072",
        sku: "S11386-WH-S",
        release_date: "2023-08-25",
        product_proposition_category: "375",
        category_level1: "Textile SOLO Group",
        category_level2: "Textile Category",
        category_level3: "T-shirts",
        color_description: "White",
        color_group: "White",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "3660734039638",
        size_textile: "S",
        color_code: "WH",
        pms_color: "WHITE",
        digital_assets: [
          {
            url: "/assets/img/apparels/women/round-neck/WH-with-logo.jpg",
            url_highress:
              "/assets/img/apparels/women/round-neck/WH-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "/assets/img/apparels/men/polo/WH-with-logo.jpg",
            url_highress: "/assets/img/apparels/men/polo/WH-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s11386-wh.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s11386-wh.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s11386-wh-back.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s11386-wh-back.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s11386-wh-side.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s11386-wh-side.jpg",
            type: "image",
            subtype: "item_picture_side",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s11386-wh-print.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s11386-wh-print.jpg",
            type: "image",
            subtype: "item_picture_printed",
          },
        ],
      },
    ],
  },
  {
    main_category_name: "Kids",
    category_name: "Kids-Round-Neck-TShirts",
    master_code: "S01166",
    master_id: "40007994",
    type_of_products: "textile",
    commodity_code: "6109 9020",
    number_of_print_positions: "5",
    country_of_origin: "CN",
    brand: "Sol's",
    product_name: "SPORTY KIDS",
    category_code: "MOBTEX_TSHJUN",
    product_class: "T-shirt",
    dimensions: "42 X 27 X 36 CM",
    length: "0",
    length_unit: "cm",
    width: "0",
    width_unit: "cm",
    height: "0",
    height_unit: "cm",
    volume: "0.5",
    volume_unit: "cdm3",
    gross_weight: "0.074",
    gross_weight_unit: "kg",
    net_weight: "0.06",
    net_weight_unit: "kg",
    inner_carton_quantity: "10",
    outer_carton_quantity: "50",
    carton_length: "0.43",
    carton_length_unit: "m",
    carton_width: "0.32",
    carton_width_unit: "m",
    carton_height: "0.2",
    carton_height_unit: "m",
    carton_volume: "0.028",
    carton_volume_unit: "m3",
    carton_gross_weight: "4.2",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/size-chart/s01166-sizechart.pdf",
        type: "document",
        subtype: "size_chart",
      },
      {
        url: "https://cdn1.midocean.com/document/oeko-tex-certificate/s01166-oekotex.pdf",
        type: "document",
        subtype: "oeko-tex_certificate",
      },
      {
        url: "https://cdn1.midocean.com/document/peta-certificate/s01166-peta.pdf",
        type: "document",
        subtype: "peta_certificate",
      },
    ],
    short_description: "SPORTY KIDS T-SHIRT SPORT",
    long_description:
      "SOL'S SPORTY Kids T-shirt in breathable polyester material with raglan sleeves, banded collar and flatlock seams makes this product ideal for any sports activity. It comes in a variety of sizes and colours as well as in neon colours. Fabric details: 140g/m² 100% polyester mesh. Only sold with print. Sizes - 6 yrs: 106-116cm (XL), 8 yrs: 118-128cm (XXL), 10 yrs: 130-140cm (3XL), 12 yrs: 142-152cm (4XL). Sublimation print available on white item only.",
    gender: "KIDS",
    material: "PET",
    printable: "yes",
    variants: [
      {
        variant_id: "10216380",
        sku: "S01166-RD-3XL",
        release_date: "2023-08-25",
        product_proposition_category: "375",
        category_level1: "Textile SOLO Group",
        category_level2: "Textile Category",
        category_level3: "T-shirts",
        color_description: "Red",
        color_group: "Red",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "3660731175339",
        size_textile: "3XL",
        color_code: "RD",
        pms_color: "200C",
        digital_assets: [
          {
            url: "/assets/img/apparels/kids/round-neck/RB-with-logo.jpg",
            url_highress:
              "/assets/img/apparels/kids/round-neck/RD-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s01166-rd.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s01166-rd.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s01166-rd-back.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s01166-rd-back.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s01166-rd-side.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s01166-rd-side.jpg",
            type: "image",
            subtype: "item_picture_side",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s01166-rd-print.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s01166-rd-print.jpg",
            type: "image",
            subtype: "item_picture_printed",
          },
        ],
      },
    ],
  },
  {
    main_category_name: "Kids",
    category_name: "Kids-Round-Neck-TShirts",
    master_code: "S01166",
    master_id: "40007994",
    type_of_products: "textile",
    commodity_code: "6109 9020",
    number_of_print_positions: "5",
    country_of_origin: "CN",
    brand: "Sol's",
    product_name: "SPORTY KIDS",
    category_code: "MOBTEX_TSHJUN",
    product_class: "T-shirt",
    dimensions: "42 X 27 X 36 CM",
    length: "0",
    length_unit: "cm",
    width: "0",
    width_unit: "cm",
    height: "0",
    height_unit: "cm",
    volume: "0.5",
    volume_unit: "cdm3",
    gross_weight: "0.074",
    gross_weight_unit: "kg",
    net_weight: "0.06",
    net_weight_unit: "kg",
    inner_carton_quantity: "10",
    outer_carton_quantity: "50",
    carton_length: "0.43",
    carton_length_unit: "m",
    carton_width: "0.32",
    carton_width_unit: "m",
    carton_height: "0.2",
    carton_height_unit: "m",
    carton_volume: "0.028",
    carton_volume_unit: "m3",
    carton_gross_weight: "4.2",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/size-chart/s01166-sizechart.pdf",
        type: "document",
        subtype: "size_chart",
      },
      {
        url: "https://cdn1.midocean.com/document/oeko-tex-certificate/s01166-oekotex.pdf",
        type: "document",
        subtype: "oeko-tex_certificate",
      },
      {
        url: "https://cdn1.midocean.com/document/peta-certificate/s01166-peta.pdf",
        type: "document",
        subtype: "peta_certificate",
      },
    ],
    short_description: "SPORTY KIDS T-SHIRT SPORT",
    long_description:
      "SOL'S SPORTY Kids T-shirt in breathable polyester material with raglan sleeves, banded collar and flatlock seams makes this product ideal for any sports activity. It comes in a variety of sizes and colours as well as in neon colours. Fabric details: 140g/m² 100% polyester mesh. Only sold with print. Sizes - 6 yrs: 106-116cm (XL), 8 yrs: 118-128cm (XXL), 10 yrs: 130-140cm (3XL), 12 yrs: 142-152cm (4XL). Sublimation print available on white item only.",
    gender: "KIDS",
    material: "PET",
    printable: "yes",
    variants: [
      {
        variant_id: "10216384",
        sku: "S01166-WH-3XL",
        release_date: "2023-08-25",
        product_proposition_category: "375",
        category_level1: "Textile SOLO Group",
        category_level2: "Textile Category",
        category_level3: "T-shirts",
        color_description: "White",
        color_group: "White",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "3660731175292",
        size_textile: "3XL",
        color_code: "WH",
        pms_color: "WHITE",
        digital_assets: [
          {
            url: "/assets/img/apparels/kids/round-neck/WH-with-logo.jpg",
            url_highress:
              "/assets/img/apparels/kids/round-neck/WH-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s01166-wh.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s01166-wh.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s01166-wh-back.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s01166-wh-back.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s01166-wh-side.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s01166-wh-side.jpg",
            type: "image",
            subtype: "item_picture_side",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s01166-wh-print.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s01166-wh-print.jpg",
            type: "image",
            subtype: "item_picture_printed",
          },
        ],
      },
    ],
  },
  {
    main_category_name: "Kids",
    category_name: "Kids-Round-Neck-TShirts",
    master_code: "S01166",
    master_id: "40007994",
    type_of_products: "textile",
    commodity_code: "6109 9020",
    number_of_print_positions: "5",
    country_of_origin: "CN",
    brand: "Sol's",
    product_name: "SPORTY KIDS",
    category_code: "MOBTEX_TSHJUN",
    product_class: "T-shirt",
    dimensions: "42 X 27 X 36 CM",
    length: "0",
    length_unit: "cm",
    width: "0",
    width_unit: "cm",
    height: "0",
    height_unit: "cm",
    volume: "0.5",
    volume_unit: "cdm3",
    gross_weight: "0.074",
    gross_weight_unit: "kg",
    net_weight: "0.06",
    net_weight_unit: "kg",
    inner_carton_quantity: "10",
    outer_carton_quantity: "50",
    carton_length: "0.43",
    carton_length_unit: "m",
    carton_width: "0.32",
    carton_width_unit: "m",
    carton_height: "0.2",
    carton_height_unit: "m",
    carton_volume: "0.028",
    carton_volume_unit: "m3",
    carton_gross_weight: "4.2",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/size-chart/s01166-sizechart.pdf",
        type: "document",
        subtype: "size_chart",
      },
      {
        url: "https://cdn1.midocean.com/document/oeko-tex-certificate/s01166-oekotex.pdf",
        type: "document",
        subtype: "oeko-tex_certificate",
      },
      {
        url: "https://cdn1.midocean.com/document/peta-certificate/s01166-peta.pdf",
        type: "document",
        subtype: "peta_certificate",
      },
    ],
    short_description: "SPORTY KIDS T-SHIRT SPORT",
    long_description:
      "SOL'S SPORTY Kids T-shirt in breathable polyester material with raglan sleeves, banded collar and flatlock seams makes this product ideal for any sports activity. It comes in a variety of sizes and colours as well as in neon colours. Fabric details: 140g/m² 100% polyester mesh. Only sold with print. Sizes - 6 yrs: 106-116cm (XL), 8 yrs: 118-128cm (XXL), 10 yrs: 130-140cm (3XL), 12 yrs: 142-152cm (4XL). Sublimation print available on white item only.",
    gender: "KIDS",
    material: "PET",
    printable: "yes",
    variants: [
      {
        variant_id: "10216356",
        sku: "S01166-FN-3XL",
        release_date: "2023-08-25",
        product_proposition_category: "375",
        category_level1: "Textile SOLO Group",
        category_level2: "Textile Category",
        category_level3: "T-shirts",
        color_description: "French Navy",
        color_group: "Blue",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "3660731274797",
        size_textile: "3XL",
        color_code: "FN",
        pms_color: "539C",
        digital_assets: [
          {
            url: "/assets/img/apparels/kids/round-neck/RD-with-logo.jpg",
            url_highress:
              "/assets/img/apparels/kids/round-neck/RD-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s01166-fn.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s01166-fn.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s01166-fn-back.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s01166-fn-back.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s01166-fn-side.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s01166-fn-side.jpg",
            type: "image",
            subtype: "item_picture_side",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s01166-fn-print.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s01166-fn-print.jpg",
            type: "image",
            subtype: "item_picture_printed",
          },
        ],
      },
    ],
  },
  {
    main_category_name: "Kids",
    category_name: "Kids-Round-Neck-TShirts",
    master_code: "S01166",
    master_id: "40007994",
    type_of_products: "textile",
    commodity_code: "6109 9020",
    number_of_print_positions: "5",
    country_of_origin: "CN",
    brand: "Sol's",
    product_name: "SPORTY KIDS",
    category_code: "MOBTEX_TSHJUN",
    product_class: "T-shirt",
    dimensions: "42 X 27 X 36 CM",
    length: "0",
    length_unit: "cm",
    width: "0",
    width_unit: "cm",
    height: "0",
    height_unit: "cm",
    volume: "0.5",
    volume_unit: "cdm3",
    gross_weight: "0.074",
    gross_weight_unit: "kg",
    net_weight: "0.06",
    net_weight_unit: "kg",
    inner_carton_quantity: "10",
    outer_carton_quantity: "50",
    carton_length: "0.43",
    carton_length_unit: "m",
    carton_width: "0.32",
    carton_width_unit: "m",
    carton_height: "0.2",
    carton_height_unit: "m",
    carton_volume: "0.028",
    carton_volume_unit: "m3",
    carton_gross_weight: "4.2",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/size-chart/s01166-sizechart.pdf",
        type: "document",
        subtype: "size_chart",
      },
      {
        url: "https://cdn1.midocean.com/document/oeko-tex-certificate/s01166-oekotex.pdf",
        type: "document",
        subtype: "oeko-tex_certificate",
      },
      {
        url: "https://cdn1.midocean.com/document/peta-certificate/s01166-peta.pdf",
        type: "document",
        subtype: "peta_certificate",
      },
    ],
    short_description: "SPORTY KIDS T-SHIRT SPORT",
    long_description:
      "SOL'S SPORTY Kids T-shirt in breathable polyester material with raglan sleeves, banded collar and flatlock seams makes this product ideal for any sports activity. It comes in a variety of sizes and colours as well as in neon colours. Fabric details: 140g/m² 100% polyester mesh. Only sold with print. Sizes - 6 yrs: 106-116cm (XL), 8 yrs: 118-128cm (XXL), 10 yrs: 130-140cm (3XL), 12 yrs: 142-152cm (4XL). Sublimation print available on white item only.",
    gender: "KIDS",
    material: "PET",
    printable: "yes",
    variants: [
      {
        variant_id: "10216352",
        sku: "S01166-BK-3XL",
        release_date: "2023-08-25",
        product_proposition_category: "375",
        category_level1: "Textile SOLO Group",
        category_level2: "Textile Category",
        category_level3: "T-shirts",
        color_description: "Black",
        color_group: "Black",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "3660731209256",
        size_textile: "3XL",
        color_code: "BK",
        pms_color: "426C",
        digital_assets: [
          {
            url: "/assets/img/apparels/kids/round-neck/NY-with-logo.jpg",
            url_highress:
              "/assets/img/apparels/kids/round-neck/NY-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s01166-bk.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s01166-bk.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s01166-bk-back.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s01166-bk-back.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s01166-bk-side.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s01166-bk-side.jpg",
            type: "image",
            subtype: "item_picture_side",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s01166-bk-print.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s01166-bk-print.jpg",
            type: "image",
            subtype: "item_picture_printed",
          },
        ],
      },
    ],
  },
  {
    main_category_name: "Kids",
    category_name: "Kids-Polo-TShirts",
    master_code: "S11344",
    master_id: "40008020",
    type_of_products: "textile",
    commodity_code: "6105 1000",
    number_of_print_positions: "5",
    country_of_origin: "BD",
    brand: "Sol's",
    product_name: "SUMMER II KIDS",
    category_code: "MOBTEX_PSHJUN",
    product_class: "Polo shirt",
    dimensions: "42 X 30 X 26 CM",
    length: "0",
    length_unit: "cm",
    width: "0",
    width_unit: "cm",
    height: "0",
    height_unit: "cm",
    volume: "0.66",
    volume_unit: "cdm3",
    gross_weight: "0.128",
    gross_weight_unit: "kg",
    net_weight: "0.112",
    net_weight_unit: "kg",
    inner_carton_quantity: "5",
    outer_carton_quantity: "50",
    carton_length: "0.46",
    carton_length_unit: "m",
    carton_width: "0.33",
    carton_width_unit: "m",
    carton_height: "0.26",
    carton_height_unit: "m",
    carton_volume: "0.039",
    carton_volume_unit: "m3",
    carton_gross_weight: "7.28",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/size-chart/s11344-sizechart.pdf",
        type: "document",
        subtype: "size_chart",
      },
      {
        url: "https://cdn1.midocean.com/document/oeko-tex-certificate/s11344-oekotex.pdf",
        type: "document",
        subtype: "oeko-tex_certificate",
      },
      {
        url: "https://cdn1.midocean.com/document/peta-certificate/s11344-peta.pdf",
        type: "document",
        subtype: "peta_certificate",
      },
    ],
    short_description: "SUMMER II KIDS Polo 170g",
    long_description:
      "SOL'S SUMMER II KIDS,  Kids' Polo Shirt, 100% Combed Ringspun Cotton Piqué 170, Rib Collar, Reinforcing Tape On Neck, Short Sleeves, Tone On Tone 3 Button Placket, Straight Hem With Side Slits, Cut And Sewn. About Sizes Matching, Please Refer To The Size Chart In The Product Documentation Section.",
    gender: "KIDS",
    material: "Cotton",
    printable: "yes",
    variants: [
      {
        variant_id: "10218251",
        sku: "S11344-RD-3XL",
        release_date: "2023-08-25",
        product_proposition_category: "378",
        category_level1: "Textile SOLO Group",
        category_level2: "Textile Category",
        category_level3: "Polo's",
        color_description: "Red",
        color_group: "Red",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "3609374006372",
        size_textile: "3XL",
        color_code: "RD",
        pms_color: "200C",
        digital_assets: [
          {
            url: "/assets/img/apparels/kids/polo/RD-with-logo.jpg",
            url_highress: "/assets/img/apparels/kids/polo/RD-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s11344-rd.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s11344-rd.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s11344-rd-back.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s11344-rd-back.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s11344-rd-side.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s11344-rd-side.jpg",
            type: "image",
            subtype: "item_picture_side",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s11344-rd-print.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s11344-rd-print.jpg",
            type: "image",
            subtype: "item_picture_printed",
          },
        ],
      },
    ],
  },
  {
    main_category_name: "Kids",
    category_name: "Kids-Polo-TShirts",
    master_code: "S11344",
    master_id: "40008020",
    type_of_products: "textile",
    commodity_code: "6105 1000",
    number_of_print_positions: "5",
    country_of_origin: "BD",
    brand: "Sol's",
    product_name: "SUMMER II KIDS",
    category_code: "MOBTEX_PSHJUN",
    product_class: "Polo shirt",
    dimensions: "42 X 30 X 26 CM",
    length: "0",
    length_unit: "cm",
    width: "0",
    width_unit: "cm",
    height: "0",
    height_unit: "cm",
    volume: "0.66",
    volume_unit: "cdm3",
    gross_weight: "0.128",
    gross_weight_unit: "kg",
    net_weight: "0.112",
    net_weight_unit: "kg",
    inner_carton_quantity: "5",
    outer_carton_quantity: "50",
    carton_length: "0.46",
    carton_length_unit: "m",
    carton_width: "0.33",
    carton_width_unit: "m",
    carton_height: "0.26",
    carton_height_unit: "m",
    carton_volume: "0.039",
    carton_volume_unit: "m3",
    carton_gross_weight: "7.28",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/size-chart/s11344-sizechart.pdf",
        type: "document",
        subtype: "size_chart",
      },
      {
        url: "https://cdn1.midocean.com/document/oeko-tex-certificate/s11344-oekotex.pdf",
        type: "document",
        subtype: "oeko-tex_certificate",
      },
      {
        url: "https://cdn1.midocean.com/document/peta-certificate/s11344-peta.pdf",
        type: "document",
        subtype: "peta_certificate",
      },
    ],
    short_description: "SUMMER II KIDS Polo 170g",
    long_description:
      "SOL'S SUMMER II KIDS,  Kids' Polo Shirt, 100% Combed Ringspun Cotton Piqué 170, Rib Collar, Reinforcing Tape On Neck, Short Sleeves, Tone On Tone 3 Button Placket, Straight Hem With Side Slits, Cut And Sewn. About Sizes Matching, Please Refer To The Size Chart In The Product Documentation Section.",
    gender: "KIDS",
    material: "Cotton",
    printable: "yes",
    variants: [
      {
        variant_id: "10218246",
        sku: "S11344-RB-3XL",
        release_date: "2023-08-25",
        product_proposition_category: "378",
        category_level1: "Textile SOLO Group",
        category_level2: "Textile Category",
        category_level3: "Polo's",
        color_description: "Royal Blue",
        color_group: "Blue",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "3609374006426",
        size_textile: "3XL",
        color_code: "RB",
        pms_color: "7686C",
        digital_assets: [
          {
            url: "/assets/img/apparels/kids/polo/RB-with-logo.jpg",
            url_highress: "/assets/img/apparels/kids/polo/RB-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s11344-rb.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s11344-rb.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s11344-rb-back.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s11344-rb-back.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s11344-rb-side.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s11344-rb-side.jpg",
            type: "image",
            subtype: "item_picture_side",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s11344-rb-print.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s11344-rb-print.jpg",
            type: "image",
            subtype: "item_picture_printed",
          },
        ],
      },
    ],
  },
  {
    main_category_name: "Kids",
    category_name: "Kids-Polo-TShirts",
    master_code: "S11344",
    master_id: "40008020",
    type_of_products: "textile",
    commodity_code: "6105 1000",
    number_of_print_positions: "5",
    country_of_origin: "BD",
    brand: "Sol's",
    product_name: "SUMMER II KIDS",
    category_code: "MOBTEX_PSHJUN",
    product_class: "Polo shirt",
    dimensions: "42 X 30 X 26 CM",
    length: "0",
    length_unit: "cm",
    width: "0",
    width_unit: "cm",
    height: "0",
    height_unit: "cm",
    volume: "0.66",
    volume_unit: "cdm3",
    gross_weight: "0.128",
    gross_weight_unit: "kg",
    net_weight: "0.112",
    net_weight_unit: "kg",
    inner_carton_quantity: "5",
    outer_carton_quantity: "50",
    carton_length: "0.46",
    carton_length_unit: "m",
    carton_width: "0.33",
    carton_width_unit: "m",
    carton_height: "0.26",
    carton_height_unit: "m",
    carton_volume: "0.039",
    carton_volume_unit: "m3",
    carton_gross_weight: "7.28",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/size-chart/s11344-sizechart.pdf",
        type: "document",
        subtype: "size_chart",
      },
      {
        url: "https://cdn1.midocean.com/document/oeko-tex-certificate/s11344-oekotex.pdf",
        type: "document",
        subtype: "oeko-tex_certificate",
      },
      {
        url: "https://cdn1.midocean.com/document/peta-certificate/s11344-peta.pdf",
        type: "document",
        subtype: "peta_certificate",
      },
    ],
    short_description: "SUMMER II KIDS Polo 170g",
    long_description:
      "SOL'S SUMMER II KIDS,  Kids' Polo Shirt, 100% Combed Ringspun Cotton Piqué 170, Rib Collar, Reinforcing Tape On Neck, Short Sleeves, Tone On Tone 3 Button Placket, Straight Hem With Side Slits, Cut And Sewn. About Sizes Matching, Please Refer To The Size Chart In The Product Documentation Section.",
    gender: "KIDS",
    material: "Cotton",
    printable: "yes",
    variants: [
      {
        variant_id: "10218261",
        sku: "S11344-WH-3XL",
        release_date: "2023-08-25",
        product_proposition_category: "378",
        category_level1: "Textile SOLO Group",
        category_level2: "Textile Category",
        category_level3: "Polo's",
        color_description: "White",
        color_group: "White",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "3609374005979",
        size_textile: "3XL",
        color_code: "WH",
        pms_color: "WHITE",
        digital_assets: [
          {
            url: "/assets/img/apparels/kids/polo/WH-with-logo.jpg",
            url_highress: "/assets/img/apparels/kids/polo/WH-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s11344-wh.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s11344-wh.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s11344-wh-back.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s11344-wh-back.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s11344-wh-side.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s11344-wh-side.jpg",
            type: "image",
            subtype: "item_picture_side",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s11344-wh-print.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s11344-wh-print.jpg",
            type: "image",
            subtype: "item_picture_printed",
          },
        ],
      },
    ],
  },
  {
    main_category_name: "Kids",
    category_name: "Kids-Polo-TShirts",
    master_code: "S11344",
    master_id: "40008020",
    type_of_products: "textile",
    commodity_code: "6105 1000",
    number_of_print_positions: "5",
    country_of_origin: "BD",
    brand: "Sol's",
    product_name: "SUMMER II KIDS",
    category_code: "MOBTEX_PSHJUN",
    product_class: "Polo shirt",
    dimensions: "42 X 30 X 26 CM",
    length: "0",
    length_unit: "cm",
    width: "0",
    width_unit: "cm",
    height: "0",
    height_unit: "cm",
    volume: "0.66",
    volume_unit: "cdm3",
    gross_weight: "0.128",
    gross_weight_unit: "kg",
    net_weight: "0.112",
    net_weight_unit: "kg",
    inner_carton_quantity: "5",
    outer_carton_quantity: "50",
    carton_length: "0.46",
    carton_length_unit: "m",
    carton_width: "0.33",
    carton_width_unit: "m",
    carton_height: "0.26",
    carton_height_unit: "m",
    carton_volume: "0.039",
    carton_volume_unit: "m3",
    carton_gross_weight: "7.28",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/size-chart/s11344-sizechart.pdf",
        type: "document",
        subtype: "size_chart",
      },
      {
        url: "https://cdn1.midocean.com/document/oeko-tex-certificate/s11344-oekotex.pdf",
        type: "document",
        subtype: "oeko-tex_certificate",
      },
      {
        url: "https://cdn1.midocean.com/document/peta-certificate/s11344-peta.pdf",
        type: "document",
        subtype: "peta_certificate",
      },
    ],
    short_description: "SUMMER II KIDS Polo 170g",
    long_description:
      "SOL'S SUMMER II KIDS,  Kids' Polo Shirt, 100% Combed Ringspun Cotton Piqué 170, Rib Collar, Reinforcing Tape On Neck, Short Sleeves, Tone On Tone 3 Button Placket, Straight Hem With Side Slits, Cut And Sewn. About Sizes Matching, Please Refer To The Size Chart In The Product Documentation Section.",
    gender: "KIDS",
    material: "Cotton",
    printable: "yes",
    variants: [
      {
        variant_id: "10218231",
        sku: "S11344-NY-3XL",
        release_date: "2023-08-25",
        product_proposition_category: "378",
        category_level1: "Textile SOLO Group",
        category_level2: "Textile Category",
        category_level3: "Polo's",
        color_description: "Navy",
        color_group: "Blue",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "3609374006228",
        size_textile: "3XL",
        color_code: "NY",
        pms_color: "296C",
        digital_assets: [
          {
            url: "/assets/img/apparels/kids/polo/NY-with-logo.jpg",
            url_highress: "/assets/img/apparels/kids/polo/NY-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s11344-ny.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s11344-ny.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s11344-ny-back.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s11344-ny-back.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s11344-ny-side.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s11344-ny-side.jpg",
            type: "image",
            subtype: "item_picture_side",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s11344-ny-print.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s11344-ny-print.jpg",
            type: "image",
            subtype: "item_picture_printed",
          },
        ],
      },
    ],
  },
  {
    main_category_name: "Kids",
    category_name: "Kids-Hoodded-Sweat-Shirt",
    master_code: "S02092",
    master_id: "40008003",
    type_of_products: "textile",
    commodity_code: "6110 3099",
    number_of_print_positions: "5",
    country_of_origin: "PK",
    brand: "Sol's",
    product_name: "STONE KIDS",
    category_code: "MOBTEX_SWJJUN",
    product_class: "Sweat shirt",
    dimensions: "58,1 X 37,8 X 42 CM",
    length: "0",
    length_unit: "cm",
    width: "0",
    width_unit: "cm",
    height: "0",
    height_unit: "cm",
    volume: "3",
    volume_unit: "cdm3",
    gross_weight: "0.325",
    gross_weight_unit: "kg",
    net_weight: "0.255",
    net_weight_unit: "kg",
    inner_carton_quantity: "5",
    outer_carton_quantity: "20",
    carton_length: "0.58",
    carton_length_unit: "m",
    carton_width: "0.39",
    carton_width_unit: "m",
    carton_height: "0.27",
    carton_height_unit: "m",
    carton_volume: "0.061",
    carton_volume_unit: "m3",
    carton_gross_weight: "7.296",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/size-chart/s02092-sizechart.pdf",
        type: "document",
        subtype: "size_chart",
      },
      {
        url: "https://cdn1.midocean.com/document/oeko-tex-certificate/s02092-oekotex.pdf",
        type: "document",
        subtype: "oeko-tex_certificate",
      },
      {
        url: "https://cdn1.midocean.com/document/peta-certificate/s02092-peta.pdf",
        type: "document",
        subtype: "peta_certificate",
      },
    ],
    short_description: "STONE KIDS ZIP HOODIE 260",
    long_description:
      "SOL'S STONE KIDS, KIDS' ZIP HOODIE, 50% Ringspun cotton - 50% polyester BRUSHED FLEECE 260, Set-in sleeves, 2 kangaroo pockets, Hood, Ribbed cuffs and bottom, Tubular. About sizes matching, please refer to the size chart in the product documentation section.",
    gender: "KIDS",
    material: "Cotton",
    printable: "yes",
    variants: [
      {
        variant_id: "10217095",
        sku: "S02092-BK-3XL",
        release_date: "2023-08-25",
        product_proposition_category: "379",
        category_level1: "Textile SOLO Group",
        category_level2: "Textile Category",
        category_level3: "Sweat shirts",
        color_description: "Black",
        color_group: "Black",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "3660731285489",
        size_textile: "3XL",
        color_code: "BK",
        pms_color: "426C",
        digital_assets: [
          {
            url: "/assets/img/apparels/kids/hoddie/BK-with-logo.jpg",
            url_highress: "/assets/img/apparels/kids/hoddie/BK-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s02092-bk.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s02092-bk.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s02092-bk-back.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s02092-bk-back.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s02092-bk-side.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s02092-bk-side.jpg",
            type: "image",
            subtype: "item_picture_side",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s02092-bk-print.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s02092-bk-print.jpg",
            type: "image",
            subtype: "item_picture_printed",
          },
        ],
      },
    ],
  },
  {
    main_category_name: "Kids",
    category_name: "Kids-Hoodded-Sweat-Shirt",
    master_code: "S02092",
    master_id: "40008003",
    type_of_products: "textile",
    commodity_code: "6110 3099",
    number_of_print_positions: "5",
    country_of_origin: "PK",
    brand: "Sol's",
    product_name: "STONE KIDS",
    category_code: "MOBTEX_SWJJUN",
    product_class: "Sweat shirt",
    dimensions: "58,1 X 37,8 X 42 CM",
    length: "0",
    length_unit: "cm",
    width: "0",
    width_unit: "cm",
    height: "0",
    height_unit: "cm",
    volume: "3",
    volume_unit: "cdm3",
    gross_weight: "0.325",
    gross_weight_unit: "kg",
    net_weight: "0.255",
    net_weight_unit: "kg",
    inner_carton_quantity: "5",
    outer_carton_quantity: "20",
    carton_length: "0.58",
    carton_length_unit: "m",
    carton_width: "0.39",
    carton_width_unit: "m",
    carton_height: "0.27",
    carton_height_unit: "m",
    carton_volume: "0.061",
    carton_volume_unit: "m3",
    carton_gross_weight: "7.296",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/size-chart/s02092-sizechart.pdf",
        type: "document",
        subtype: "size_chart",
      },
      {
        url: "https://cdn1.midocean.com/document/oeko-tex-certificate/s02092-oekotex.pdf",
        type: "document",
        subtype: "oeko-tex_certificate",
      },
      {
        url: "https://cdn1.midocean.com/document/peta-certificate/s02092-peta.pdf",
        type: "document",
        subtype: "peta_certificate",
      },
    ],
    short_description: "STONE KIDS ZIP HOODIE 260",
    long_description:
      "SOL'S STONE KIDS, KIDS' ZIP HOODIE, 50% Ringspun cotton - 50% polyester BRUSHED FLEECE 260, Set-in sleeves, 2 kangaroo pockets, Hood, Ribbed cuffs and bottom, Tubular. About sizes matching, please refer to the size chart in the product documentation section.",
    gender: "KIDS",
    material: "Cotton",
    printable: "yes",
    variants: [
      {
        variant_id: "10217099",
        sku: "S02092-FN-3XL",
        release_date: "2023-08-25",
        product_proposition_category: "379",
        category_level1: "Textile SOLO Group",
        category_level2: "Textile Category",
        category_level3: "Sweat shirts",
        color_description: "French Navy",
        color_group: "Blue",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "3660731285625",
        size_textile: "3XL",
        color_code: "FN",
        pms_color: "539C",
        digital_assets: [
          {
            url: "/assets/img/apparels/kids/hoddie/FN-with-logo.jpg",
            url_highress: "/assets/img/apparels/kids/hoddie/FN-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s02092-fn.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s02092-fn.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s02092-fn-back.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s02092-fn-back.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s02092-fn-side.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s02092-fn-side.jpg",
            type: "image",
            subtype: "item_picture_side",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/s02092-fn-print.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/s02092-fn-print.jpg",
            type: "image",
            subtype: "item_picture_printed",
          },
        ],
      },
    ],
  },
];

export default ApparelsProducts;
