import React from "react";

function Banner() {
  return (
    <div>
      <div className="home-slides owl-carousel owl-theme">
        <div className="main-banner banner-bg1">
          <div className="d-table">
            <div className="d-table-cell">
              <div className="container"></div>
            </div>
          </div>
        </div>
        <div className="main-banner banner-bg2">
          <div className="d-table">
            <div className="d-table-cell">
              <div className="container"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Banner;
